import React from "react";
import { Modal, ModalHeader, Form, ModalBody, Dropdown } from "reactstrap";
import { DropdownToggle, DropdownMenu, Spinner } from 'reactstrap';
import SeasonDropDown from './SeasonDropDown';
import { Program } from "./Types";

interface State {
    episode: Program | undefined;
    isSeasonDropdownOpen: boolean;
}
interface Props {
    showEpisodeModal: boolean;
    isModalLoading: boolean;
    item: Program;
    seasons: Program[][];
    setModalVisibility: (show: boolean) => void;
    generateEpisodeTable: (item: Program) => JSX.Element;

}

class EpisodeModal extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            episode: undefined,
            isSeasonDropdownOpen: false
        };
    }

    private onToggleModal = (): void => {
        this.props.setModalVisibility(!this.props.showEpisodeModal);
    }

    private setEpisodeData = (episode: Program) => {
        this.setState({ episode });
    }

    private dropdownToggle = () => {
        this.setState({ isSeasonDropdownOpen: !this.state.isSeasonDropdownOpen });
    }

    public render(): JSX.Element {
        const { showEpisodeModal, seasons } = this.props;
        const { episode, isSeasonDropdownOpen } = this.state;

        return (
            <>
                <Modal isOpen={showEpisodeModal} size="lg">
                    <ModalHeader toggle={this.onToggleModal}>
                        Episodes
                        </ModalHeader>
                    {this.props.isModalLoading && <Spinner
                        color="primary"
                        className="spinner"
                    />}
                    {!this.props.isModalLoading && (
                        <Form id="category-edit-form" className="cubo-form">
                            <ModalBody>
                                {seasons && seasons.length > 0 && (
                                    <Dropdown isOpen={isSeasonDropdownOpen} toggle={this.dropdownToggle}>
                                        <DropdownToggle color="primary" caret>
                                            Seasons
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            {seasons.map((season, indexSeason) => (
                                                <SeasonDropDown
                                                    key={indexSeason}
                                                    season={season}
                                                    indexSeason={indexSeason}
                                                    setEpisodeData={this.setEpisodeData}
                                                    isSeasonDropdownOpen={isSeasonDropdownOpen}
                                                    dropdownToggle={this.dropdownToggle}
                                                />
                                            ))
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                )}

                                {episode && this.props.generateEpisodeTable(episode)}
                            </ModalBody>
                        </Form>
                    )}
                </Modal>
            </>
        );
    }
}

export default EpisodeModal;
