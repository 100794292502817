import React, { ChangeEvent } from "react";
import { Button, Form, Input, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

interface Props {
    disabled: boolean;
    submitHandler: (findBy: string, textInputValue: string) => Promise<any>;
}

interface State {
    textInputValue: string;
    findBy: "email" | "username";
}

enum STRINGS {
    FIND_BY = "Find by:",
    EMAIL = "E-Mail",
    USERNAME = "Username",
    SEARCH = "Search",
}

class FindContractsForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            findBy: "email",
            textInputValue: "",
        };
    }

    private submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        const { textInputValue, findBy } = this.state;
        const { submitHandler } = this.props;

        event.preventDefault();

        try {
            await submitHandler(findBy, textInputValue);
            this.setState({ textInputValue: "" });
        } catch (err: unknown) {
            if (err instanceof Error) {
                // console.log(err.message);
            } else {
                console.log("Unknown error in FindContractsForm.tsx:submitForm", err);
            }
        }
    };

    private handleInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ textInputValue: event.target.value });
    };

    private switchInput = (findBy: "username" | "email"): void => {
        if (this.state.findBy !== findBy) {
            this.setState({ findBy });
        }
    };

    public render(): JSX.Element {
        const { textInputValue, findBy } = this.state;
        const { FIND_BY, EMAIL, USERNAME, SEARCH } = STRINGS;
        const inputType = findBy === "email" ? "email" : "text";

        return (
            <Form id="contracts-form" onSubmit={this.submitForm} style={{ textAlign: "left" }}>
                <fieldset disabled={this.props.disabled}>
                    <Row>
                        <Col sm={1}>
                            <UncontrolledDropdown color="primary">
                                <DropdownToggle caret>{findBy === "email" ? EMAIL : USERNAME}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>{FIND_BY}</DropdownItem>
                                    <DropdownItem onClick={(): void => this.switchInput("email")}>{EMAIL}</DropdownItem>
                                    <DropdownItem onClick={(): void => this.switchInput("username")}>{USERNAME}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col sm={5}>
                            <Input type={inputType} name="inputText" id="inputText" onChange={this.handleInputValueChange} required value={textInputValue} />
                        </Col>
                        <Col sm={1}>
                            <Button color="primary">{SEARCH}</Button>
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        );
    }
}

export default FindContractsForm;
