import axios, { AxiosInstance } from 'axios';
import { PinoLog } from "../../helpers/PinoLog";
import Util from "../../helpers/Util";

export interface TaskResponse {
    logs: PinoLog[],
    taskStatus: string
}

class TaskService {
    private endpoint = "/tasks";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getLogs(reportName: string): Promise<TaskResponse> {
        try {
            const res = await this.service.get(`${this.endpoint}/${reportName}/logs`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async start(taskName: string): Promise<any> {
        try {
            const res = await this.service.post(`${this.endpoint}/${taskName}`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new TaskService();
