import AccountCleanup from "./components/AccountCleanup/AccountCleanup";
import CSRTool from "./components/CSRTool/CSRTool";
import CompAccounts from "./components/CompAccounts/CompAccounts";
import Forbidden from "./components/Forbidden";
import GiftVouchers from "./components/GiftVouchers/GiftVouchers";
import Plans from "./components/Plans/Plans";
import Programs from "./components/Programs/Programs";
import SystemLogs from "./components/SystemLogs/SystemLogs";
import TaskRunner from "./components/TaskRunner/TaskRunner";
import Admin from "./components/Users/Admin";

export const routes = [
    {
        path: "/task-runner",
        component: <TaskRunner />,
    },
    {
        path: "/comp-accounts",
        component: <CompAccounts />,
    },
    {
        path: "/csr-tool",
        component: <CSRTool />,
    },
    {
        path: "/account-cleanup",
        component: <AccountCleanup />,
    },
    {
        path: "/plans",
        component: <Plans />,
    },
    {
        path: "/gift-vouchers",
        component: <GiftVouchers />,
    },
    {
        path: "/user-management",
        component: <Admin />,
    },
    {
        path: "/programs",
        component: <Programs />,
    },
    {
        path: "/system-logs",
        component: <SystemLogs />,
    },
    {
        path: "/forbbiden",
        component: <Forbidden />,
    },
];
