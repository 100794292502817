export enum LogLevel {
    TRACE = 10,
    DEBUG = 20,
    INFO = 30,
    WARN = 40,
    ERROR = 50,
    FATAL = 60
}

export class PinoLog {
    public constructor(
        public level: number,
        public time: number,
        public msg: string,
        public pid: number,
        public hostname: string) {

    }

    public static newInstance(logMsg: PinoLog): PinoLog {
        return new PinoLog(logMsg.level, logMsg.time, logMsg.msg, logMsg.pid, logMsg.hostname);
    }

    public static getLogType(logLevel: number): string {
        try {
            const levels: any = {
                10: "trace",
                20: "debug",
                30: "info",
                40: "warn",
                50: "error",
                60: "fatal"
            };
            return levels[logLevel];
        } catch (err) {
            return "info";
        }
    }

}