import { io, Socket } from 'socket.io-client';

class WebSocketHelper {

    public getSocket(): Socket {
        let origin = window.location.origin;
        if (origin.indexOf('localhost') !== -1) {
            // Development
            origin = 'http://localhost';
        }
        const socket = io(origin);
        socket.on("connect", (): void => {
            console.log("Socket connected");            
        });

        return socket.connect();
    }

    public watchEvent(socket: Socket, eventName: string, handler: (data: any, eventName: string) => void): void {
        socket.on(eventName, (data: any): void => {
            handler(data, eventName);
        });
        socket.on('disconnect', (): void => {
            console.log("Disconnected");
        });
    }

}

export default new WebSocketHelper();
