import React, { PureComponent } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Program } from './Types';
interface Props {
    season: Program[],
    indexSeason: number
    isSeasonDropdownOpen: boolean;
    setEpisodeData: (episode: Program) => void
    dropdownToggle: () => void
}

class SeasonDropDown extends PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    private handleModal = (episode: Program) => {
        this.props.dropdownToggle();
        this.props.setEpisodeData(episode);
    }

    public render(): JSX.Element {
        const { season, indexSeason } = this.props;
        const sortedSeason = season.sort((ep, prevEp) => ep.tvSeasonEpisodeNumber - prevEp.tvSeasonEpisodeNumber);

        return (
            <>
                <UncontrolledDropdown direction="right">
                 {sortedSeason.length ?  ( 
                    <>
                        <DropdownToggle caret className="season-buttons" color="none">
                            Season {indexSeason + 1}
                        </DropdownToggle>
                        <DropdownMenu >
                            {sortedSeason
                                .map((episode, index) => (
                                    <DropdownItem
                                    key={episode.id + index}
                                    onClick={() => this.handleModal(episode)}
                                        >Episode {episode.tvSeasonEpisodeNumber}: {episode.title}</DropdownItem>
                                    ))}
                        </DropdownMenu>
                    </>
                    ):null}
                </UncontrolledDropdown>
            </>
        );
    }
}

export default SeasonDropDown;
