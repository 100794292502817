import React, { Component } from "react";
import { Col, Table, Spinner } from "reactstrap";
import { PinoLog } from "../../helpers/PinoLog";
import Util from "../../helpers/Util";

interface Props {
    logs: PinoLog[];
    isLoading: boolean;
}

interface State {
    nothingHere: string;
}

class CSRAccountsLog extends Component<Props, State> {

    public render(): JSX.Element {
        return (
            <Col className='csr-accounts'>
                <Table className="table table-striped table-bordered">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.logs.map((log, index): JSX.Element => {
                                return (
                                    <tr key={index}>
                                        <td>{Util.unixTimeToFormattedDate(log.time)}</td>
                                        <td>{log.msg}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {this.props.isLoading && <Spinner color="primary" />}
            </Col>
        );
    }

}

export default CSRAccountsLog;
