import axios, { AxiosInstance } from 'axios';
import Util from "../../helpers/Util";

export interface GiftVoucher {
    promotionId: string;
    giftVoucherInfo: {
        currency: string;
        activationDate: number; // date
        purchasePriceIncludingTax: number;
        purchaseDate: number;
        purchasingUserId: string;
        redeemingUserId: string;
        redemptionDate: number;
        revoked?: boolean;
    };
    userIds: string[];
    promotionTitle: string;
    promotionDescription: string;
    code: string;
    promotionCodeId: string;
}

class GiftVoucherService {
    private endpoint = "/giftvouchers";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getGiftVouchersByEmail(userEmail: string, findBy: string): Promise<GiftVoucher[]> {
        try {
            const res = await this.service.get(`${this.endpoint}?email=${userEmail}&findBy=${findBy}`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new GiftVoucherService();
