import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Util from "../../helpers/Util";

export interface PlansConfig {
    pageCode: string;
    buttons: Buttons[];
}

export interface Buttons {
    buttonCode: string;
    filters: string;
}

export interface ProductTag {
    scheme: string;
    schemeLocalized: any;
    title: string;
    titleLocalized: any;
}

export interface PlanEntry {
    id: string;
    title: string;
    description: string;
    productTags: ProductTag[];
    scopeIds: string[];
}

export interface PlansResponse {
    startIndex: number;
    itemsPerPage: number;
    entryCount: number;
    entries: PlanEntry[];
}

class PlansService {
    private endpoint = "/plans";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getAll(): Promise<PlansConfig[]> {
        try {
            const res = await this.service.get(this.endpoint);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getPlansFromMPX(baseFilter: string): Promise<PlanEntry[]> {
        const countryWildcard = "[country]";
        const trialWildcard = "[trialEligible]";

        const buildFilterRequest = (baseFilter: string): Promise<PlansResponse> => {
            const config = {
                params: {
                    filters: baseFilter
                }
            };

            return this.service.get(`${this.endpoint}/mpx`, config);
        };

        const hasFreeTrialWildcard = baseFilter.includes(trialWildcard);
        const hasCountryWildcard = baseFilter.includes(countryWildcard);
        const filterRequests: Promise<PlansResponse>[] = [];
        if (hasCountryWildcard) {
            ["US", "CA"].forEach((country) => {
                const filter = baseFilter.replace(countryWildcard, country);
                if (hasFreeTrialWildcard) {
                    ["Yes", "No"].forEach((opt) => {
                        filterRequests.push(buildFilterRequest(filter.replace(trialWildcard, opt)));
                    });
                } else {
                    filterRequests.push(buildFilterRequest(filter));
                }
            });
        }

        try {
            const responses = await Promise.all(filterRequests);
            return responses.map((res: unknown) => (res as AxiosResponse<PlansResponse>).data)
                .reduce((acc: PlanEntry[], curr) => {
                    return [...acc, ...curr.entries];
                }, []);
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async update(payload: PlansConfig[]): Promise<boolean> {
        try {
            await this.service.put(this.endpoint, payload);
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new PlansService();
