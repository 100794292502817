import { Thumbnails } from "../../components/Programs/Types";
import Util from "../../helpers/Util";

export interface Promotion {
    id: string;
    guid: string;
    updated: number;
    title: string;
    description: string;
    added: number;
    locked: boolean;
    availableDate: number;
    expirationDate: number;
    active: boolean;
}

class ImageResizerService {
    public baseUrl = process.env.REACT_APP_API_BASE_URL;
    public apiKey: string | undefined = process.env.REACT_APP_API_BASE_KEY;

    public async getFallBackThumbnailUrl() {
        return "https://admin-portal-image-template.s3.amazonaws.com/notFoundImage.webp";
    }

    public async getResizedThumbnailUrl({ thumbnails, imageType }: { thumbnails: Thumbnails; imageType: keyof Thumbnails }): Promise<string | undefined> {
        try {
            const imgTypeUrl = thumbnails?.[imageType]?.url;

            const img = imgTypeUrl ? `http://${imgTypeUrl?.split("//")[1]}` : null;

            const fallbackImgUrl = "https://admin-portal-image-template.s3.amazonaws.com/notFoundImage.webp";

            const url = img ?? fallbackImgUrl;

            const resizedImageUrl = `?url=${url}&h=300&output=webp`;

            return `${this.baseUrl}${resizedImageUrl}`;
        } catch (error: unknown) {
            Util.handleServiceError(error, Error().stack?.toString());
        }
        return;
    }
}

export default new ImageResizerService();
