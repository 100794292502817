import React from "react";
import { AlertCircle, CheckCircle } from "react-feather";
import { RouteComponentProps, withRouter } from "react-router";
import { Col, Row, Spinner, UncontrolledAlert } from "reactstrap";
import { AccountCleanUpEnum, AvailableRoutesEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { LogLevel, PinoLog } from "../../helpers/PinoLog";
import WebSocketHelper from "../../helpers/WebSocketHelper";
import { withPermission } from "../../hoc/withPermission";
import AccountCleanupService from "../../services/PureFlixAdmin/AccountCleanupService";
import { AccountCleanupRequest } from "../../services/PureFlixAdmin/Requests";
import TaskService from "../../services/PureFlixAdmin/TaskService";
import Title from "../Title";
import AccountCleanupForm from "./AccountCleanupForm";
import CSRAccountsLog from "./CSRAccountsLog";

type AlertType = "danger" | "warning" | "success";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

interface State {
    alertMessage: string;
    alertType: AlertType;
    isLoading: boolean;
    processMessages: PinoLog[];
    logsCSRAccounts: PinoLog[];
    areLogsCSRAccountsLoading: boolean;
    cleanupBy: string;
}

enum STRINGS {
    PAGE_TITLE = "Account Cleanup",
}

const Div = ({ ...props }) => <div {...props} />;
const GuardAccountCleanUpLogs = withPermission(CSRAccountsLog, AccountCleanUpEnum.VIEW);
const GuardAccountCleanUpProcessMessages = withPermission(Div, AccountCleanUpEnum.VIEW);

class AccountCleanup extends React.PureComponent<PropsType, State> {
    private cleanupLogs = React.createRef<HTMLDivElement>();
    private _isMounted;

    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            isLoading: true,
            processMessages: [],
            logsCSRAccounts: [],
            areLogsCSRAccountsLoading: true,
            cleanupBy: "E-Mail",
        };
        this._isMounted = false;
    }
    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.initialize();
            this.hideSpinner();
        }
    }
    public componentWillUnmount(): void {
        this._isMounted = false;
    }
    private async initialize(): Promise<void> {
        try {
            const isLoggedIn = await this.checkLoggedIn();
            if (isLoggedIn) {
                const socket = await WebSocketHelper.getSocket();
                WebSocketHelper.watchEvent(socket, "account-cleanup", this.appendToProcessMessages);
                WebSocketHelper.watchEvent(socket, "csr-accounts", this.appendToCSRAccountsMessages);
                socket.connect();
                await this.getLogs();
            }
        } catch (err) {
            this.setError(err);
        }
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: any) {
            this.props.history.push("/", { message: err.message });
            return false;
        }
    };

    private async getLogs(): Promise<void> {
        if (this._isMounted) {
            this.setState({
                logsCSRAccounts: [],
                areLogsCSRAccountsLoading: true,
            });
            const { logs } = await TaskService.getLogs("csr-accounts");
            this.setState({ logsCSRAccounts: logs, areLogsCSRAccountsLoading: false });
        }
    }

    private appendToProcessMessages = (log: PinoLog): void => {
        const newState = {
            processMessages: this.state.processMessages.concat([log]),
            isLoading: this.state.cleanupBy !== "E-Mail" && log.msg !== "Done",
        };
        this.setState(newState);
    };

    private appendToCSRAccountsMessages = (log: PinoLog): void => {
        const newState = {
            logsCSRAccounts: [log].concat(this.state.logsCSRAccounts),
        };
        this.setState(newState);
    };

    public componentDidUpdate(): void {
        this.scrollToBottom();
    }

    private startCleanup = async (payload: AccountCleanupRequest): Promise<any> => {
        try {
            this.setAlert("");
            this.showSpinner();
            this.setState({ cleanupBy: payload.cleanupBy });
            await AccountCleanupService.startCleanup(payload);
        } catch (error: any) {
            if (payload.cleanupBy === "E-Mail") {
                let myMessage = "Unknown Error in AccountCleanup.tsx:startCleanup";
                if (error instanceof Error) {
                    myMessage = error.message;
                }
                const errorMsg = myMessage.replace("Error: ", "");
                if (errorMsg !== "The user was not found" && errorMsg !== "Network Error") {
                    this.setAlert(myMessage.replace("Error: ", ""), "warning");
                }
            }
        } finally {
            this.hideSpinner();
        }
    };

    private setError = (err: any, alertType: AlertType = "danger"): void => {
        const alertMessage = err.response ? err.response.data.message : err.message;
        this.setAlert(alertMessage, alertType);
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private showSpinner(): void {
        this.setState({ isLoading: true, processMessages: [] });
    }

    private hideSpinner(): void {
        this.setState({ isLoading: false });
    }

    private renderAlert(): JSX.Element {
        return (
            <UncontrolledAlert color={this.state.alertType} className="alert">
                {this.state.alertMessage}
            </UncontrolledAlert>
        );
    }

    private scrollToBottom = (): void => {
        const node = this.cleanupLogs.current;
        if (node) {
            node.scrollTop = node.scrollHeight - node.clientHeight;
        }
    };

    public render(): JSX.Element {
        const { isLoading, alertMessage } = this.state;

        return (
            <div className="account-cleanup-page container-fluid">
                <Title content={STRINGS.PAGE_TITLE} />

                <Row>
                    <Col>
                        <AccountCleanupForm submitHandler={this.startCleanup} disabled={isLoading} />
                        {alertMessage && this.renderAlert()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GuardAccountCleanUpProcessMessages ref={this.cleanupLogs} className="account-cleanup-logs">
                            {isLoading && <Spinner color="primary" id="logs-spinner" />}
                            <ul>
                                {this.state.processMessages.map(
                                    (log, index): JSX.Element => (
                                        <li key={index}>
                                            {log.level === LogLevel.ERROR || log.level === LogLevel.FATAL || log.level === LogLevel.WARN ? <AlertCircle /> : <CheckCircle />}
                                            &nbsp;{log.msg}
                                        </li>
                                    )
                                )}
                            </ul>
                        </GuardAccountCleanUpProcessMessages>
                    </Col>
                    <GuardAccountCleanUpLogs logs={this.state.logsCSRAccounts} isLoading={this.state.areLogsCSRAccountsLoading} />
                </Row>
            </div>
        );
    }
}

export default withRouter(withPermission(AccountCleanup, AvailableRoutesEnum.ACCOUNT_CLEANUP));
