import React from "react";
import { Alert } from "reactstrap";

export default class Forbidden extends React.Component {
    public render(): JSX.Element {
        return (
            <Alert color="danger">You are not allowed to Access this resource. Please contact your administrator.</Alert>
        );
    }
}
