import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";

class Util {
    public static getAPIBase(): string {
        const currentUrl = window.location.href;
        if (currentUrl.indexOf("localhost") !== -1) {
            // Development
            return `http://localhost${process.env.REACT_APP_API_BASE}`;
        }
        return process.env.REACT_APP_API_BASE || "/api/v1";
    }

    public static getAxiosConfig(): AxiosRequestConfig {
        return {
            baseURL: Util.getAPIBase(),
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
        };
    }

    public static unixTimeInSeconds(unixTime: number): number {
        if (unixTime.toString().length < 13) {
            return unixTime;
        }
        return Math.round(unixTime / 1000);
    }

    public static unixTimeToFormattedDate(unixTime: number, format = ""): string {
        return dayjs.unix(Util.unixTimeInSeconds(unixTime)).format(format);
    }

    public static timestampToUTCString(timestamp: number): string {
        return new Date(timestamp).toUTCString();
    }

    public static secondsToHHMMSS(seconds: string | number = 0): string {
        let remainingSeconds = parseInt(seconds as string, 10);

        const hours = Math.floor(remainingSeconds / 3600);
        remainingSeconds = remainingSeconds % 3600;

        const minutes = Math.floor(remainingSeconds / 60);
        remainingSeconds = remainingSeconds % 60;

        const hh = String(hours).padStart(2, "0");
        const mm = String(minutes).padStart(2, "0");
        const ss = String(remainingSeconds).padStart(2, "0");

        return `${hh}:${mm}:${ss}`;
    }

    /**
     * Sorts alphabetically an array of objects by some specific key.
     * @param {String} property Key of the object to sort.
     */
    public static dynamicSort(property: string): any {
        let sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a: any, b: any): any {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        };
    }

    public static validateInput(input: string): string {
        const trimmedInput = input.trim();
        if (/\s/.test(trimmedInput)) {
            throw new Error("White spaces found");
        }
        return input;
    }

    public static checkDuplicates(names: string[], nameToCheck: string): void {
        names.forEach((name) => {
            if (name === nameToCheck) {
                throw new Error(`${name} already exists`);
            }
        });
    }

    public static handleServiceError(error: unknown, callStack: unknown): Error {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                return new Error(error.response.data.message);
            } else if (error.request) {
                return new Error(`Request error: ${error.message}`);
            } else {
                return new Error(`Request error without error.response or error.request in ${error} : ${callStack}`);
            }
        } else if (error instanceof Error) {
            return new Error(`${error.name}: ${error.message}`);
        } else {
            return new Error(`Unknown error: ${JSON.stringify(error)}`);
        }
    }

    public static cleanEnvString(envValue: string): string {
        return String(envValue?.replace(/"/g, "")?.trim()); // Clean the account value
    }

    public static getOwnerId(): string {
        if (this.cleanEnvString(process.env.NODE_ENV) !== "production") {
            return this.cleanEnvString(process.env.REACT_APP_MPX_OWNER_ACCOUNT_DEVZ as string);
        }
        return this.cleanEnvString(process.env.REACT_APP_MPX_OWNER_ACCOUNT_PRDZ as string);
    }
}

export default Util;
