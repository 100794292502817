import { useState } from "react";
import { Button } from "reactstrap";
import Roles from "./Roles";
import AdminUsers from "./Users";

export default function Admin(): React.Component {
    const [selectedTab, setSelectedTab] = useState<string>("users");

    const handleSelect = (tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <div className="csr-tool-page" style={{ margin: 4 }}>
            <Button tabIndex={0} color={selectedTab === "users" ? "primary" : "secondary"} onClick={() => handleSelect("users")}>
                Users
            </Button>
            <Button tabIndex={1} color={selectedTab === "roles" ? "primary" : "secondary"} onClick={() => handleSelect("roles")} style={{ marginLeft: ".5rem" }}>
                Roles
            </Button>
            {selectedTab === "users" ? <AdminUsers /> : <Roles />}
            <div style={{ marginTop: "15px" }}></div>
        </div>
    );
}
