import axios, { AxiosInstance } from 'axios';
import Util from "../../helpers/Util";
import { AccountCleanupRequest } from "./Requests";

class CompAccountsService {
    private endpoint = "/account-cleanup";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async startCleanup(payload: AccountCleanupRequest): Promise<{ message: string }> {
        try {
            const res = await this.service.post(this.endpoint, payload.toFormData());
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new CompAccountsService();
