import React from "react";
import { Alert, Button, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import { User } from "../../services/PureFlixAdmin/UserService";
import { formatRoleName, RoleEnum, roles } from "../../config/permissions";

interface Props {
    selectedUser: Partial<User>;
    onSaveUser: (user: User) => Promise<User>;
    onHide: () => void;
}

const STRINGS = {
    CREATE_USER: "Creating new User",
    EDIT_USER: "Editing user",
};

interface State {
    userData: Partial<User>;
    errorMsg?: string;
}

export default class UserModal extends React.PureComponent<Props, State> {
    public state = {
        userData: this.props.selectedUser,
        errorMsg: undefined,
    };

    private onFormSubmit = async (e?: React.FormEvent): Promise<void> => {
        const { onSaveUser } = this.props;
        const { userData } = this.state;
        if (e) {
            e.preventDefault();
        }
        try {
            await onSaveUser(userData as User);
            this.onHide();
        } catch (err: unknown) {
            let errorMessage = "";
            if (err instanceof Error) {
                errorMessage = err.message;
            } else {
                errorMessage = "Unknown error in UserModal.tsx:onFormSubmit";
            }
            this.setState({ errorMsg: errorMessage });
        }
    };

    private onHide = (): void => {
        this.props.onHide();
    };

    public render(): JSX.Element {
        const { userData, errorMsg } = this.state;
        const { onHide } = this.props;
        return (
            <Modal isOpen={true} size="lg" toggle={onHide}>
                <Form id="category-edit-form" className="cubo-form" onSubmit={this.onFormSubmit}>
                    <ModalHeader toggle={this.onHide}>{userData.username ? STRINGS.EDIT_USER : STRINGS.CREATE_USER}</ModalHeader>
                    <ModalBody>
                        <div>
                            {errorMsg && (
                                <Alert color="warning" className="alert" style={{ minWidth: "100%" }}>
                                    {errorMsg}
                                </Alert>
                            )}
                        </div>
                        <div className="form-group row">
                            <Label htmlFor="usernameField" className="col-sm-2 col-form-label">
                                User Name
                            </Label>
                            <div className="col-sm-10">
                                <Input
                                    type="text"
                                    name="inputText"
                                    id="inputText"
                                    onChange={(event): void => this.setState({ userData: { ...userData, username: event.target.value } })}
                                    required
                                    value={userData.username || ""}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <Label htmlFor="accessLevelField" className="col-sm-2 col-form-label">
                                Role
                            </Label>
                            <div className="col-sm-10">
                                <UncontrolledDropdown color="primary">
                                    <DropdownToggle caret>{formatRoleName(userData.role as RoleEnum) || "Select Role"}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Select Role</DropdownItem>
                                        {roles.map((role) => (
                                            <DropdownItem key={role} onClick={(): void => this.setState({ userData: { ...userData, role } })}>
                                                {formatRoleName(role)}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onFormSubmit} className="btn btn-dark" color="primary" disabled={!userData.username || !userData.role}>
                            Save
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
