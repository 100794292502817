import AuthService from "../services/PureFlixAdmin/AuthService";

class AuthHelper {
    private tokenKey = "auth-token";
    private permissionsKey = "permissions";
    private notAllowedMsg = "You need to be logged in to access the admin panel";

    public async isTokenSet(): Promise<boolean> {
        try {
            const token = this.getToken();

            /* eslint-disable-next-line */
            if (token !== null) {
                return true;
            }

            throw new Error(this.notAllowedMsg);
        } catch (err) {
            localStorage.removeItem(this.tokenKey);
            return false;
        }
    }

    public checkEveryMinuteIfTokenIsValid(): NodeJS.Timeout {
        return setInterval((): void => {
            this.checkLoggedIn("Your token has expired. Please login again.");
        }, 60000);
    }

    public async checkLoggedIn(errorMsg = ""): Promise<boolean> {
        try {
            const token = this.getToken();

            /* eslint-disable-next-line */
            if (token === null) {
                throw new Error(this.notAllowedMsg);
            }

            const result = await AuthService.getLoginStatus(token);

            if (!result.userName) {
                throw new Error(errorMsg || this.notAllowedMsg);
            }

            return true;
        } catch (err) {
            this.logout();
            throw err;
        }
    }

    public logout(): void {
        const token = this.getToken();
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.permissionsKey);

        if (token) {
            AuthService.logout(token); // don't need to wait here
        }
    }

    private getToken(): string | null {
        return localStorage.getItem(this.tokenKey);
    }

    // TODO: Refactor the code to remove this method
    public getRootPathWithNotAllowedMsg(errorMsg = ""): string {
        return `/?msg=${errorMsg || this.notAllowedMsg}`;
    }
}

export default new AuthHelper();
