import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";

interface Props {
    onConfirm: () => Promise<void>;
    userEmail: string | undefined;
    setModalVisibility: (show: boolean) => void;
}

interface State {
    isLoading: boolean;
}

export default class DeleteUserProfileModal extends React.Component<Props, State> {
    public state = {
        isLoading: false,
    };

    private onDeleteUser = () => {
        this.setState({isLoading: true});
        this.props.onConfirm();
    };

    private closeModal = (): void => {
        if (!this.state.isLoading) {
            this.props.setModalVisibility(false);
        }
    };

    public render(): JSX.Element {
        const {userEmail} = this.props;
        const {isLoading} = this.state;
        return (
            <Modal isOpen={true} size="md">
                <ModalHeader toggle={this.closeModal}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the User Data/Profile information from this user: {userEmail}. <strong>THIS ACTION CANNOT BE UNDONE</strong>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.onDeleteUser} color="danger" disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" color="white" /> : "Confirm"}
                    </Button>
                    <Button onClick={this.closeModal} color="primary" disabled={isLoading}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
