import React, { PureComponent } from "react";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ModalFormType } from "../../helpers/ModalFormType";
import Util from "../../helpers/Util";
import { PlansConfig } from "../../services/PureFlixAdmin/PlansService";

// Your component own properties
interface PropsType {
    showModalConfigName: boolean;
    indexConfig: number;
    config: PlansConfig;
    configList: PlansConfig[];
    toggleConfigNameModal: (show: boolean, type?: ModalFormType) => void;
    editPlanConfigName: (pageCode: string, indexConfig: number) => Promise<void>;
}

interface State {
    disableForm: boolean;
    msg: string;
    pageCode: string;
}

class PlansEditConfigForm extends PureComponent<PropsType, State> {
    public state: State = {
        msg: "",
        disableForm: false,
        pageCode: "",
    };

    private toggleModalButton = (): void => {
        this.props.toggleConfigNameModal(!this.props.showModalConfigName);
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ pageCode: event.target.value });
    };

    private saveConfig = (event: React.FormEvent<HTMLFormElement>, indexConfig: number): void => {
        try {
            event.preventDefault();
            this.setState({ disableForm: true });

            const pageCodes = this.props.configList.map((config) => config.pageCode);
            Util.checkDuplicates(pageCodes, this.state.pageCode);

            const pageCode = Util.validateInput(this.state.pageCode);

            this.props.editPlanConfigName(pageCode, indexConfig);

            this.setState({ disableForm: false });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            } else {
                this.setState({ msg: "Unknown error in PlansEditConfigForm.tsx:saveConfig", disableForm: false });
            }
        }
    };

    public render(): JSX.Element {
        const { showModalConfigName, indexConfig } = this.props;
        return (
            <Modal isOpen={showModalConfigName}>
                <ModalHeader toggle={this.toggleModalButton}>Rename Page Code </ModalHeader>
                <Form id="category-edit-form" className="cubo-form" onSubmit={(event): void => this.saveConfig(event, indexConfig)}>
                    <fieldset disabled={this.state.disableForm}>
                        <ModalBody>
                            <div className="form-group row">
                                <Label htmlFor="codeField" className="col-sm-2 col-form-label">
                                    Plan Code
                                </Label>
                                <div className="col-sm-10">
                                    <Input
                                        type="text"
                                        name="pageCode"
                                        className="form-control"
                                        id="codeField"
                                        aria-describedby="codeHelp"
                                        required
                                        onChange={this.handleChange}
                                        defaultValue={this.props.config.pageCode || this.state.pageCode}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                                {this.state.msg}
                            </span>
                            <Button type="submit" className="btn btn-dark" color="secondary">
                                Save Button
                            </Button>
                        </ModalFooter>
                    </fieldset>
                </Form>
            </Modal>
        );
    }
}

export default PlansEditConfigForm;
