import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { PinoLog } from "../../helpers/PinoLog";
import Util from "../../helpers/Util";

interface Props {
    logs: PinoLog[];
}

interface State {
    content: string;
}

class LogsTable extends Component<Props, State> {
    public render(): JSX.Element {
        return (
            <Row>
                <Col>
                    <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Level</th>
                                <th scope="col">Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.logs.map((log, index): JSX.Element => {
                                    return (
                                        <tr key={index}>
                                            <td>{Util.unixTimeToFormattedDate(log.time, "YYYY-MM-DD H:mm:ss (Z)")}</td>
                                            <td>{PinoLog.getLogType(log.level)}</td>
                                            <td>{log.msg}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    }

}

export default LogsTable;
