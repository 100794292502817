import React, { PureComponent } from "react";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ModalFormType } from "../../helpers/ModalFormType";
import Util from "../../helpers/Util";
import { Buttons, PlansConfig } from "../../services/PureFlixAdmin/PlansService";

interface PropsType {
    showModalButton: boolean;
    config: PlansConfig;
    indexConfig: number;
    indexButton: number;
    modalFormType: ModalFormType;
    button: Buttons;
    toggleButtonModal: (show: boolean) => void;
    updatePlans: (button: Buttons, indexPlan: number, indexButton: number, modalFormType: string) => Promise<void>;
}

interface State {
    disableForm: boolean;
    msg: string;
    buttonCode: string;
    filters: string;
}

class PlansButtonForm extends PureComponent<PropsType, State> {
    public state: State = {
        msg: "",
        disableForm: false,
        buttonCode: "",
        filters: "",
    };

    private toggleModalButton = (): void => {
        this.props.toggleButtonModal(!this.props.showModalButton);
        this.setState({
            buttonCode: "",
            filters: "",
            msg: "",
        });
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === "buttonCode") {
            this.setState({ buttonCode: event.target.value });
        } else {
            this.setState({ filters: event.target.value });
        }
    };

    private sendButton = (event: React.FormEvent<HTMLFormElement>, indexButton: number): void => {
        try {
            event.preventDefault();
            this.setState({ disableForm: true });

            const buttons = this.props.config.buttons.map((button) => button.buttonCode);
            Util.checkDuplicates(buttons, this.state.buttonCode);

            const plan = {
                buttonCode: Util.validateInput(this.state.buttonCode) || this.props.button.buttonCode,
                filters: Util.validateInput(this.state.filters) || this.props.button.filters,
            };

            this.props.updatePlans(plan, this.props.indexConfig, indexButton, this.props.modalFormType);

            this.setState({ disableForm: false });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            } else {
                this.setState({ msg: "Unknown error in PlansButtonForm.tsx:sendButton", disableForm: false });
            }
        }
    };

    public render(): JSX.Element {
        const { showModalButton, indexButton } = this.props;

        return (
            <Modal isOpen={showModalButton} toggle={this.toggleModalButton}>
                <ModalHeader toggle={this.toggleModalButton}>{this.props.modalFormType === ModalFormType.ADD ? "Add" : "Edit"} Plan</ModalHeader>
                <Form id="category-edit-form" className="cubo-form" onSubmit={(event): void => this.sendButton(event, indexButton)}>
                    <fieldset disabled={this.state.disableForm}>
                        <ModalBody>
                            <div className="form-group row">
                                <Label htmlFor="codeField" className="col-sm-2 col-form-label">
                                    Plan Code
                                </Label>
                                <div className="col-sm-10">
                                    <Input
                                        type="text"
                                        name="buttonCode"
                                        className="form-control"
                                        id="codeField"
                                        aria-describedby="codeHelp"
                                        required
                                        onChange={this.handleChange}
                                        defaultValue={
                                            this.props.modalFormType === ModalFormType.ADD ? this.state.buttonCode : this.props.button.buttonCode || this.state.buttonCode
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <Label htmlFor="filtersField" className="col-sm-2 col-form-label">
                                    Filters
                                </Label>
                                <div className="col-sm-10">
                                    <Input
                                        type="text"
                                        name="filters"
                                        className="form-control"
                                        id="filtersField"
                                        aria-describedby="filtersHelp"
                                        required
                                        onChange={this.handleChange}
                                        defaultValue={this.props.modalFormType === ModalFormType.ADD ? this.state.filters : this.props.button.filters || this.state.filters}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                                {this.state.msg}
                            </span>
                            <Button type="submit" className="btn btn-dark" color="secondary">
                                Save Button
                            </Button>
                        </ModalFooter>
                    </fieldset>
                </Form>
            </Modal>
        );
    }
}

export default PlansButtonForm;
