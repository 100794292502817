import React, { PureComponent } from "react";
import { Button, Table } from "reactstrap";
import { MembershipAccountInformationEnum, MembershipAccountUpdateEnum } from "../../config/permissions";
import Util from "../../helpers/Util";
import { withPermission } from "../../hoc/withPermission";
import { CompAccountListItem, PaymentInstrument } from "./CSRTool";

interface Props {
    contracts: CompAccountListItem[];
    paymentInstruments: PaymentInstrument[];
    customerProfile: any;
    onShowSelectPromotionModal: (visible: boolean) => void;
    onShowCancelModal: (contractId: string) => void;
    onShowDeletePaymentInstrumentModal: () => void;
    onShowUpdateUserEmailModal: (visible: boolean) => void;
    onShowDeleteUserModal: (visible: boolean) => void;
}

enum STRINGS {
    EMAIL = "Email",
    USERNAME = "Username",
    SUBSCRIPTION = "Basic Subscription",
    PREMIUM_SUBSCRIPTION = "Premium Subscription",
    START_DATE = "Start Date",
    EXPIRATION_DATE = "Expiration Date",
    DAYS_TO_EXPIRE = "Days to Expire",
}

const H1 = ({ ...props }) => <h1 {...props} />;
const Div = ({ ...props }) => <div {...props} />;
const GuardCancelSubscription = withPermission(Button, MembershipAccountInformationEnum.CANCEL);
const GuardUserProfileActionsBlock = withPermission(Div, MembershipAccountInformationEnum.CANCEL);
const GuardSwithcMembershipButton = withPermission(Button, MembershipAccountInformationEnum.SWITCH);
const GuardUpdateUserProfile = withPermission(Button, MembershipAccountUpdateEnum.CHANGE_EMAIL);
const GuardDeleteUserProfile = withPermission(Button, MembershipAccountUpdateEnum.DELETE);
const GuardUserProfileActionsTitle = withPermission(H1, MembershipAccountUpdateEnum.DELETE);
class CustomerInfoTable extends PureComponent<Props> {
    private static makeRow(key: string, value: string | number): JSX.Element {
        return (
            <tr key={key}>
                <th style={{ width: "30%" }}>{key}:</th>
                <td>{value}</td>
            </tr>
        );
    }

    private onShowPromotionModal = (): void => {
        this.props.onShowSelectPromotionModal(true);
    };

    private onShowCancelModal = (contractId: string): void => {
        this.props.onShowCancelModal(contractId);
    };

    private onShowDeletePaymentInstrumentModal = (): void => {
        this.props.onShowDeletePaymentInstrumentModal();
    };

    private onShowDeleteUserModal = () => {
        this.props.onShowDeleteUserModal(true);
    };

    private generateProfileTable = (): JSX.Element => {
        const { EMAIL, USERNAME } = STRINGS;
        const { email, username, id } = this.props.customerProfile;
        const allowCancelPaymentIntrument = !!this.props.contracts.find((x) => (x.productStorefront === "Web" && x.active) || (x.productStorefront === "Web" && !x.active));

        const ProfileTable = (): JSX.Element => (
            <React.Fragment>
                <Table hover={true} bordered={true} className="contracts-rows">
                    <tbody>{[CustomerInfoTable.makeRow(EMAIL, email.toLowerCase()), CustomerInfoTable.makeRow(USERNAME, username.toLowerCase())]}</tbody>
                </Table>
                <hr />
            </React.Fragment>
        );

        const UpdateUserEmailButton = (): JSX.Element => (
            <GuardUpdateUserProfile color="primary" size="sm" onClick={() => this.props.onShowUpdateUserEmailModal(true)}>
                Update User Profile
            </GuardUpdateUserProfile>
        );

        const DeleteUserButton = (): JSX.Element => (
            <GuardDeleteUserProfile color="danger" size="sm" onClick={() => this.onShowDeleteUserModal()}>
                Delete User Profile
            </GuardDeleteUserProfile>
        );

        return (
            <>
                <GuardUserProfileActionsBlock className="contract-actions">
                    <GuardUserProfileActionsTitle>User Actions</GuardUserProfileActionsTitle>
                    <div>
                        {this.props.paymentInstruments.length > 0 && allowCancelPaymentIntrument && (
                            <GuardUpdateUserProfile color="danger" size="sm" onClick={this.onShowDeletePaymentInstrumentModal}>
                                Cancel Payment Instrument
                            </GuardUpdateUserProfile>
                        )}
                        <UpdateUserEmailButton />
                        <DeleteUserButton />
                    </div>
                </GuardUserProfileActionsBlock>

                <div className="contracts-table">
                    <h2>Profile</h2>
                    <ProfileTable key={id} />
                </div>
            </>
        );
    };

    private generateContractRows = (itemData: CompAccountListItem): JSX.Element[] => {
        const { START_DATE, EXPIRATION_DATE, DAYS_TO_EXPIRE } = STRINGS;
        const { contractStartDate, contractEndDate, activitySummary, active, productStorefront, title } = itemData;
        const canUpdateContract = active && productStorefront === "Web";
        const pattern = new RegExp("premium", "i");
        const isPremium = pattern.test(title);

        const subscriptionRow = (
            <tr key={STRINGS.SUBSCRIPTION}>
                <th style={{ backgroundColor: isPremium ? "greenyellow" : "transparent" }}>{isPremium ? STRINGS.PREMIUM_SUBSCRIPTION : STRINGS.SUBSCRIPTION}</th>
                <td>
                    <div className="subscription-row">
                        <div>{title}</div>
                        {canUpdateContract && (
                            <div>
                                <GuardSwithcMembershipButton color="primary" size="sm" className="update-contract" onClick={this.onShowPromotionModal}>
                                    Apply Promotion
                                </GuardSwithcMembershipButton>
                            </div>
                        )}
                        {canUpdateContract && (
                            <div>
                                <GuardCancelSubscription color="danger" size="sm" className="update-contract" onClick={(): void => this.onShowCancelModal(itemData.id)}>
                                    Cancel Subscription
                                </GuardCancelSubscription>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        );

        return [
            subscriptionRow,
            CustomerInfoTable.makeRow(START_DATE, Util.timestampToUTCString(contractStartDate)),
            CustomerInfoTable.makeRow(EXPIRATION_DATE, Util.timestampToUTCString(contractEndDate)),
            CustomerInfoTable.makeRow(DAYS_TO_EXPIRE, activitySummary.daysRemaining),
        ];
    };

    private generateContractTables = (contracts: CompAccountListItem[]): JSX.Element => {
        type TContractMap = { activeContracts: CompAccountListItem[]; inactiveContracts: CompAccountListItem[] };

        const contractMap = contracts.reduce(
            (map: TContractMap, contract: CompAccountListItem) => {
                if (contract.active) {
                    map.activeContracts.push(contract);
                } else {
                    map.inactiveContracts.push(contract);
                }
                return map;
            },
            { activeContracts: [], inactiveContracts: [] }
        );

        const { activeContracts, inactiveContracts } = contractMap;

        const ContractTable = ({ contract }: { contract: CompAccountListItem }): JSX.Element => (
            <React.Fragment>
                <Table hover={true} bordered={true} className="contracts-rows">
                    <tbody>{this.generateContractRows(contract)}</tbody>
                </Table>
                <hr />
            </React.Fragment>
        );

        return (
            <>
                {activeContracts.length > 0 && (
                    <div className="contracts-table">
                        <h2>Active Contracts</h2>
                        {activeContracts.map((contract, index) => (
                            <ContractTable contract={contract} key={index} />
                        ))}
                    </div>
                )}

                {inactiveContracts.length > 0 && (
                    <div className="contracts-table">
                        <h2>Inactive Contracts</h2>
                        {inactiveContracts.map((contract, index) => (
                            <ContractTable contract={contract} key={index} />
                        ))}
                    </div>
                )}
            </>
        );
    };

    public render(): JSX.Element | null {
        const { contracts = [], customerProfile } = this.props;

        if (!contracts.length && !customerProfile) return null;

        return (
            <>
                {customerProfile && this.generateProfileTable()}
                {contracts.length > 0 && this.generateContractTables(contracts)}
            </>
        );
    }
}

export default CustomerInfoTable;
