import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Permission, RoleEnum } from "../config/permissions";

interface RoleState {
    role: RoleEnum;
    permissions: Permission[];
    setRole: (role: RoleEnum | undefined) => void;
    togglePermission: (role: RoleEnum, permission: Permission) => any;
}

export const usePermissionStore = create<RoleState>()(
    devtools(
        persist(
            (set) => ({
                role: RoleEnum.ADMIN_FULL,
                permissions: [],
                setRole: (role: RoleEnum | undefined) => set(() => ({ role: role })),
                togglePermission: (role: RoleEnum, permission: Permission) =>
                    set((state) => {
                        const currentPermissions = new Set(state.permissions || []);
                        if (currentPermissions.has(permission)) {
                            currentPermissions.delete(permission);
                        } else {
                            currentPermissions.add(permission);
                        }
                        return {
                            permissions: {
                                ...state.permissions,
                                [role]: Array.from(currentPermissions),
                            },
                        };
                    }),
            }),
            {
                name: "role-storage",
            }
        )
    )
);
