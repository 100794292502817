import axios, {AxiosInstance} from "axios";
import Util from "../../helpers/Util";

export interface SystemLog {
    id: number;
    username: string;
    action: string;
    resource: string;
    logDate: Date;
}

export interface SystemLogResponse {
    rows: SystemLog[];
    count: number;
}

class SystemLogService {
    private endpoint = "/syslogs";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getLogs(params: {username: string; date: Date; page: number}): Promise<SystemLogResponse> {
        try {
            const res = await this.service.get(this.endpoint, {params});
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new SystemLogService();
