import React from "react";
import { Alert, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row, Spinner, Table, UncontrolledDropdown } from "reactstrap";
import GiftVoucherService, { GiftVoucher } from "../../services/PureFlixAdmin/GiftVoucherService";
import Title from "../Title";

enum STRINGS {
    PAGE_TITLE = "Gift Vouchers",
    SEARCH = "Search",
    NO_VOUCHERS = "There are no gift vouchers for the provided email"
}

enum SearchBy {
    RECIPIENT = "Recipient",
    PURCHASER = "Purchaser"
}

interface State {
    isLoading: boolean;
    alertMessage?: string;
    input: string;
    userEmail?: string;
    giftVoucherList?: GiftVoucher[];
    searchBy: SearchBy;

}

export default class GiftVouchers extends React.Component<Record<string, any>, State> {

    private constructor(props: Record<string, any>) {
        super(props);
        this.state = {
            isLoading: false,
            input: "",
            userEmail: "",
            searchBy: SearchBy.PURCHASER,
        };
    }

    private onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ input: event.target.value });
    }

    private onSearch = async (event: React.FormEvent | React.MouseEvent): Promise<any> => {
        const { input, searchBy } = this.state;
        event.preventDefault();
        if (!input) {
            return;
        }
        this.setState({ isLoading: true, userEmail: input });
        // Callbacking this instead of async awaiting because react setState calls
        GiftVoucherService.getGiftVouchersByEmail(input, searchBy).then((vouchers: GiftVoucher[]) => {
            vouchers = vouchers.filter((v) =>  v.giftVoucherInfo?.revoked !== true);
            this.setState({
                giftVoucherList: vouchers,
                isLoading: false
            });
        }).catch(() => {
            // it returns 404 if email not found, so we treat it like this
            this.setState({ isLoading: false, giftVoucherList: [] });
        });
        return false;
    }

    private Form = (): JSX.Element => {
        const { input } = this.state;
        return (
            <Form id='gift-vouchers-form' style={{ display: "flex" }} onSubmit={this.onSearch}>
                <fieldset>
                    <Row>
                        <Col sm={3} md={3}>
                            <FormGroup>
                                <UncontrolledDropdown color="primary">
                                    <DropdownToggle caret size="md">{this.state.searchBy}</DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem header>Search By:</DropdownItem>
                                        <DropdownItem onClick={(): void => this.setState({ searchBy: SearchBy.PURCHASER })}>Purchaser</DropdownItem>
                                        <DropdownItem onClick={(): void => this.setState({ searchBy: SearchBy.RECIPIENT })}>Recipient</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>
                        <Col sm={8} md={8}>
                            <FormGroup>                                
                                <Input
                                    type="text"
                                    name="inputText"
                                    id="inputText"
                                    onChange={this.onChangeInput}
                                    required
                                    value={input}
                                    />
                            </FormGroup>
                        </Col>
                        <Col sm={1}md={1}>
                            <FormGroup>

                            <Button color="primary" onClick={this.onSearch}>{STRINGS.SEARCH}</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        );
    }

    private VouchersTable = (): JSX.Element => {
        const { giftVoucherList, isLoading } = this.state;

        if (isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }
        if (!giftVoucherList) {
            return <></>;
        }

        if (giftVoucherList.length === 0) {
            return (
                <div>
                    <Alert color="primary">{STRINGS.NO_VOUCHERS}</Alert>
                </div>
            );
        }

        const getRedemptionInfo = (timestamp: number): string => timestamp ? new Date(timestamp).toLocaleString() : "-";

        const Header = (): JSX.Element => (
            <tr>
                <th>Purchaser Email</th>
                <th>Recipient Email</th>
                <th>Voucher Code</th>
                <th>Purchase Date</th>
                <th>Promotion Title</th>
                <th>Is Activated?</th>
                <th>Redemption Date</th>
            </tr>
        );

        const Body = (): JSX.Element => {
            return (
                <>
                    {giftVoucherList.map((voucher: GiftVoucher, index: number): JSX.Element => (
                        <tr key={index}>
                            <td>{this.getPurchasingUserEmail(voucher)}</td>
                            <td>{this.getRedeemingUserEmail(voucher)}</td>
                            <td>{voucher.code}</td>
                            <td>{new Date(voucher.giftVoucherInfo.purchaseDate).toLocaleString()}</td>
                            <td>{voucher.promotionTitle}</td>
                            <td>{voucher.giftVoucherInfo?.redemptionDate ? "Yes" : "No"}</td>
                            <td>{getRedemptionInfo(voucher.giftVoucherInfo.redemptionDate)}</td>
                        </tr>
                    ))}
                </>
            );
        };
        return (
            <Table>
                <thead>
                    <Header />
                </thead>
                <tbody>
                    <Body />
                </tbody>
            </Table>
        );
    }

    private getRedeemingUserEmail = (voucher: GiftVoucher): string => {
        if (!voucher.userIds || voucher.userIds.length === 0) {
            return "-";
        }
        const redeemingUserMail = voucher.userIds.find((user) => user.startsWith("mailto:"));
        return redeemingUserMail && redeemingUserMail.replace("mailto:", "") || "";
    }

    private getPurchasingUserEmail = (voucher: GiftVoucher): string => {
        if (!voucher.userIds || voucher.userIds.length === 0) {
            return "-";
        }
        const purchasingUserEmail = voucher.userIds.find((user) => user.startsWith("purchaser:"));
        return purchasingUserEmail && purchasingUserEmail.replace("purchaser:", "") || "-";
    }

    public render(): JSX.Element {
        return (
            <div className='csr-tool-page'>
                <Title content={STRINGS.PAGE_TITLE} />

                <this.Form />
                <div style={{ marginTop: "15px" }}>
                    <this.VouchersTable />
                </div>
            </div>
        );
    }
}
