import { Permission, permissions, RoleEnum } from "../config/permissions";
import { usePermissionStore } from "../store/usePermissionStore";

function hasRequiredPermission(requiredPermission: Permission): boolean {
    const userRole = usePermissionStore((state) => state.role);
    return permissions[userRole as RoleEnum].includes(requiredPermission);
}

export function hasRequiredRoles(roles: RoleEnum[], userRole: RoleEnum): boolean {
    return roles.some((role) => role === userRole);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withPermission(Component: any, requiredPermission: Permission, redirect = ""): (props: any) => JSX.Element | null {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    const WithRolesWrapper = (props: any) => {
        const hasPermission = hasRequiredPermission(requiredPermission);
        if (hasPermission) {
            return <Component ref={props.ref} {...props} />;
        } else {
            if (redirect) {
                props.history.push(redirect);
            }
            return null;
        }
    };

    return WithRolesWrapper;
}

// USAGE:

// const GuardComponent = withPermission(Button, MembershipAccountInformation.CANCEL);
// <GuardComponent tabIndex={1} color={selectedTab === "roles" ? "primary" : "secondary"} onClick={() => handleSelect("roles")} style={{ marginLeft: ".5rem" }} >Test</GuardComponent>
