import React from "react";
import {Alert, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {PaymentInstrument} from "./CSRTool";

interface Props {
    paymentInstruments: PaymentInstrument[];
    onHide: () => void;
    onDeletePaymentInformation: (paymentInstruments: PaymentInstrument[]) => Promise<void>;
}

interface State {
    isLoading: boolean;
    alertMessage?: string;
}

export default class CancelPaymentInstrumentModal extends React.PureComponent<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    private onSubmitCancellation = async (): Promise<void> => {
        const {paymentInstruments, onHide, onDeletePaymentInformation} = this.props;

        this.setState({
            isLoading: true,
            alertMessage: undefined,
        });
        try {
            await onDeletePaymentInformation(paymentInstruments);
            onHide();
        } catch (error: unknown) {
            let errorMessage = "";
            if (error instanceof Error) {
                errorMessage = error.message;
            } else {
                errorMessage = "Unknown error in CancelPaymentInstrumentModal.tsx:onSubmitCancellation";
            }
            this.setState({
                isLoading: false,
                alertMessage: errorMessage,
            });
        }
    };

    public render(): JSX.Element {
        const {onHide} = this.props;
        const {isLoading, alertMessage} = this.state;

        return (
            <Modal isOpen={true} size="lg">
                <ModalHeader toggle={onHide}>Confirmation </ModalHeader>
                <Form id="cancel-subscription-form" className="cubo-form">
                    <ModalBody>
                        <div>
                            <p>Are you sure you want to delete the Payment information?</p>
                            {alertMessage && (
                                <Alert color="warning" className="alert" style={{minWidth: "100%"}}>
                                    {alertMessage}
                                </Alert>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onSubmitCancellation} color="danger" disabled={isLoading}>
                            {isLoading ? <Spinner size="sm" color="white" /> : "Confirm"}
                        </Button>
                        <Button color="primary" onClick={onHide} disabled={isLoading}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
