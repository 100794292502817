import { Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Permission, Role, RoleEnum, formatRoleName, permissionsTree } from "../../config/permissions";

interface Props {
    selectedRole: RoleEnum;
    onHide: () => void;
    permissions: { [x in keyof typeof Role]: Permission[] };
}

const RoleModal = ({ onHide, selectedRole, permissions }: Props): React.ReactElement => {
    return (
        <Modal isOpen={true} size="lg" toggle={onHide} scrollable>
            <ModalHeader toggle={onHide}>View {formatRoleName(selectedRole)} Role</ModalHeader>
            <ModalBody>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                    {Object.entries(permissionsTree).map(([feature, abac]: [string, Permission[]]) => (
                        <>
                            <Label style={{ fontWeight: 600 }}>{feature}</Label>
                            {abac.map((permission: Permission) => (
                                <>
                                    <Label
                                        key={permission}
                                        htmlFor="roleField"
                                        className="col-sm-7 col-form-label"
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
                                    >
                                        <input
                                            style={{ margin: 10, width: 18, height: 18 }}
                                            type="checkbox"
                                            defaultChecked={permissions[selectedRole].includes(permission) || false}
                                        />
                                        {permission.replaceAll("_", " ")}{" "}
                                    </Label>
                                </>
                            ))}
                        </>
                    ))}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default RoleModal;
