import React, { Component } from 'react';
import { Label, Input, Button } from 'reactstrap';
import { Buttons } from '../../services/PureFlixAdmin/PlansService';

// Your component own properties
interface PropsType {
    buttonCode: string;
    filters: string;
    indexButton: number;
    buttons: Buttons[]
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, indexButton: number) => void;
    removeConfigFormButton: (indexButton: number) => void;
}

class PlansButtonConfig extends Component<PropsType> {

    public render(): JSX.Element {
        return (
            <>
                <hr className="my-2" />
                <div className="form-group row">
                    <Label htmlFor="buttonField" className="col-sm-2 col-form-label">Plan Code</Label>
                    <div className="col-sm-10">
                        <Input type="text" name="buttonCode" className="form-control"
                            id="buttonField" aria-describedby="buttonHelp" required
                            onChange={(event): void => (this.props.handleChange(event, this.props.indexButton))}
                            value={this.props.buttonCode}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <Label htmlFor="filtersField" className="col-sm-2 col-form-label">Filters</Label>
                    <div className="col-sm-10">
                        <Input type="text" name="filters" className="form-control"
                            id="filtersField" aria-describedby="filtersHelp" required
                            onChange={(event): void => (this.props.handleChange(event, this.props.indexButton))}
                            value={this.props.filters}
                        />
                    </div>
                </div>
                <div className="remove-plan-button">
                    <Button
                        disabled={this.props.buttons.length <= 1 ? true : false}
                        color='primary'
                        className='add-config'
                        onClick={() => this.props.removeConfigFormButton(this.props.indexButton)}
                    >Remove plan
                    </Button>
                </div>
                <p></p>
            </>
        );
    }
}

export default PlansButtonConfig;
