import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Util from "../../helpers/Util";

export interface cancelContractPayload {
    refund: boolean;
    atNextAction: boolean;
    refundAmount?: number;
    fulfillmentItemRef?: string;
}

interface RefundAmount {
    amount: number;
    fullAmount: number;
    fulfillmentItemRef?: string;
}

class ContractService {
    private endpoint = "/contracts";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getUserContracts(findBy: string, inputValue: string, onlyCompContracts: boolean, byActive?: boolean): Promise<any> {
        try {
            const config = {
                params: { findBy, inputValue, onlyCompContracts, byActive },
            };
            const res = await this.service.get(this.endpoint, config);
            return res.data;
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    throw new Error(error.response.data.message);
                } else {
                    throw new Error("Axios error without error.response in ContractService.tsx:getUserContracts");
                }
            } else {
                throw new Error("Unknown error in ContractService.tsx:getUserContracts");
            }
        }
    }

    public async cancelContractSubscription(contractId: string, payload: cancelContractPayload): Promise<boolean> {
        const config: AxiosRequestConfig = {
            data: {
                ...payload,
            },
        };
        try {
            await this.service.delete(`${this.endpoint}/${contractId}`, config);
            return true;
        } catch (error: unknown) {
            // console.log(error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    throw new Error(error.response.data.message);
                } else {
                    throw new Error("Axios error without error.response in ContractService.tsx:getUserContracts");
                }
            } else {
                throw new Error("Unknown error in ContractService.tsx:getUserContracts");
            }
        }
    }

    public async getRefundAmount(contractId: string): Promise<RefundAmount> {
        try {
            const res = await this.service.get(`${this.endpoint}/${contractId}/refund-amount`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new ContractService();
