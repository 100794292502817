import classnames from "classnames";
import { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Nav, NavItem, NavLink, Spinner, TabContent, TabPane, UncontrolledAlert } from "reactstrap";
import { AvailableRoutesEnum, RoleEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { hasRequiredRoles, withPermission } from "../../hoc/withPermission";
import ContractsService, { cancelContractPayload } from "../../services/PureFlixAdmin/ContractService";
import CustomerService, { WatchListBeaconItem, WatchlistItem } from "../../services/PureFlixAdmin/CustomerService";
import PromotionService, { Promotion } from "../../services/PureFlixAdmin/PromotionService";
import Title from "../Title";
import CancelPaymentInstrumentModal from "./CancelPaymentInstrumentModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import CustomerInfoTable from "./CustomerInfoTable";
import CustomerWatchlistBeaconTable from "./CustomerWatchlistBeaconTable";
import CustomerWatchlistTable from "./CustomerWatchlistTable";
import DeleteUserProfileModal from "./DeleteUserProfileModal";
import FindContractsForm from "./FindContractsForm";
import PromotionModal from "./PromotionModal";
import UpdateCustomerModal from "./UpdateCustomerModal";
import { usePermissionStore } from "../../store/usePermissionStore";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

enum TabIds {
    contract = "contract",
    history = "history",
    beacon = "beacon",
}

type AlertType = "danger" | "warning" | "success";

interface State {
    activeTab: keyof typeof TabIds;
    alertMessage: string;
    alertType: AlertType;
    isLoading: boolean;
    contracts: CompAccountListItem[];
    paymentInstruments: PaymentInstrument[];
    customerProfile: any;
    watchlist: WatchlistItem[];
    watchlistBeacon: WatchListBeaconItem[];
    isPromotionModalVisible: boolean;
    isCancelModalVisible: boolean;
    isPaymentInstrumentModalVisible: boolean;
    isDeleteUserModalVisible: boolean;
    selectedContractId?: string;
    isUpdateCustomerModalVisible: boolean;
    userRole: RoleEnum;
}

enum STRINGS {
    PAGE_TITLE = "CSR Tool",
}

export interface CompAccountListItem {
    id: string;
    title: string;
    contractStartDate: number;
    contractEndDate: number;
    active: boolean;
    activitySummary: {
        daysRemaining: number;
    };
    productStorefront: string;
}

export interface PaymentInstrument {
    id: string;
    userId: string;
    name: string;
    properties: {
        cardName: string;
        cardType: string;
        expirationMonth: string;
        expirationYear: string;
        last4: string;
        shopperEmail: string;
    };
}

class CSRTool extends PureComponent<PropsType, State> {
    public constructor(props: PropsType) {
        super(props);
        this.state = {
            activeTab: TabIds.contract,
            alertMessage: "",
            alertType: "danger",
            isLoading: true,
            contracts: [],
            paymentInstruments: [],
            customerProfile: null,
            watchlist: [],
            watchlistBeacon: [],
            isPromotionModalVisible: false,
            isCancelModalVisible: false,
            isPaymentInstrumentModalVisible: false,
            isDeleteUserModalVisible: false,
            selectedContractId: undefined,
            isUpdateCustomerModalVisible: false,
            userRole: usePermissionStore.getState().role,
        };
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: any) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            }
            return false;
        }
    };

    public componentDidMount(): void {
        this.checkLoggedIn();
        this.hideSpinner();
    }

    private getTableData = async (findBy: string, inputValue: string): Promise<any> => {
        try {
            const { userRole } = this.state;
            this.setAlert("");
            this.setState({ customerProfile: null, contracts: [], paymentInstruments: [] });
            this.showSpinner();

            const customerProfile = await CustomerService.getProfile(findBy, inputValue);
            const numericUserId = customerProfile.userId.split("/").pop();

            const [contracts, paymentInstruments] = await Promise.all([
                ContractsService.getUserContracts(findBy, inputValue, false),
                hasRequiredRoles([RoleEnum.MSR_ADVANCED, RoleEnum.MSR_SUPERVISOR, RoleEnum.ADMIN_READ, RoleEnum.ADMIN_FULL], userRole)
                    ? CustomerService.getPaymentInstruments(customerProfile.username)
                    : Promise.resolve([]),
            ]);

            const [watchlist, watchlistBeacon] = await Promise.all([CustomerService.getWatchlist(numericUserId), CustomerService.getWatchlistFromBeaconReport(numericUserId)]);

            if (watchlist && watchlist.length > 10) {
                watchlist.length = 10;
            }

            this.setState({
                customerProfile,
                contracts,
                paymentInstruments,
                watchlist,
                watchlistBeacon,
            });
        } catch (error: any) {
            if (error instanceof Error) {
                this.setAlert(error.message.replace("Error: ", ""), "warning");
            }
        }

        this.hideSpinner();
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private showSpinner(): void {
        this.setState({ isLoading: true });
    }

    private hideSpinner(): void {
        this.setState({ isLoading: false });
    }

    private renderAlert(): JSX.Element {
        const { alertMessage, alertType } = this.state;
        return <UncontrolledAlert color={alertType}>{alertMessage}</UncontrolledAlert>;
    }

    private toggle(tabId: keyof typeof TabIds): void {
        if (this.state.activeTab !== tabId) {
            this.setState({ activeTab: tabId });
        }
    }

    public showCancelSubscriptionModal = (contractId: string): void => {
        this.setState({
            isCancelModalVisible: true,
            selectedContractId: contractId.split("/").pop(),
        });
    };

    public showDeletePaymentInstrumentModal = (): void => {
        this.setState({
            isPaymentInstrumentModalVisible: true,
        });
    };

    private showUpdateCustomerModal = (show: boolean): void => {
        this.setState({
            isUpdateCustomerModalVisible: show,
        });
    };

    private showDeleteUserModal = (isDeleteUserModalVisible: boolean): void => {
        this.setState({ isDeleteUserModalVisible });
    };

    public onConfirmContractCancellation = async (contractId: string, params: cancelContractPayload): Promise<void> => {
        const { contracts } = this.state;
        await ContractsService.cancelContractSubscription(contractId, params);
        this.setState({
            selectedContractId: undefined,
            alertType: "success",
            alertMessage: "Subscription cancelled Successfully",
            isCancelModalVisible: false,
            contracts: contracts.filter((c: CompAccountListItem) => c.id.split("/").pop() !== contractId),
        });
    };

    public onConfirmPaymentInstrumentCancellation = async (): Promise<void> => {
        const { customerProfile } = this.state;
        await CustomerService.deletePaymentInstruments(customerProfile.username);
        this.setState({
            alertType: "success",
            alertMessage: "Payment information cancelled successfully",
            isPaymentInstrumentModalVisible: false,
            paymentInstruments: [],
        });
    };

    public showSelectPromotionModal = (show: boolean): void => {
        this.setState({
            isPromotionModalVisible: show,
        });
    };

    public applyPromotionToContract = async (promotion: Promotion): Promise<void> => {
        const contract = this.state.contracts[0];
        await PromotionService.applyPromotionToContract(contract.id, promotion.id);
        return;
    };

    public updateUserProfile = async (id: string, profileData: Record<string, string>): Promise<void> => {
        const { customerProfile } = this.state;
        await CustomerService.updateUserProfile(customerProfile.username, profileData);
        this.setState({
            isUpdateCustomerModalVisible: false,
            alertMessage: "User profile updated successfully",
            alertType: "success",
            customerProfile: {
                ...customerProfile,
                email: profileData.newEmail,
            },
        });
    };

    public deleteUserProfile = async (): Promise<void> => {
        try {
            const { customerProfile } = this.state;
            if (!customerProfile || !customerProfile.email) {
                throw Error("Email is not provided");
            }
            await CustomerService.deleteUserProfile(customerProfile.email);
            this.setState({
                isDeleteUserModalVisible: false,
                alertMessage: "User profile deleted successfully",
                alertType: "success",
                customerProfile: null,
                contracts: [],
            });
        } catch (e: any) {
            let myMessage = "";
            if (e instanceof Error) {
                myMessage = e.message;
            }
            this.setState({
                isDeleteUserModalVisible: false,
                alertMessage: `There was an error while deleting the user profile: ${myMessage}`,
                alertType: "danger",
            });
        }
    };

    private makeTabs(): JSX.Element {
        const { activeTab, contracts: activeContracts, paymentInstruments, customerProfile, watchlist, watchlistBeacon } = this.state;

        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === TabIds.contract })}
                            onClick={(): void => {
                                this.toggle(TabIds.contract);
                            }}
                        >
                            User Information
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === TabIds.history })}
                            onClick={(): void => {
                                this.toggle(TabIds.history);
                            }}
                        >
                            Recently watched (MPX)
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === TabIds.beacon })}
                            onClick={(): void => {
                                this.toggle(TabIds.beacon);
                            }}
                        >
                            Recently watched (Beacon report)
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId={TabIds.contract}>
                        <CustomerInfoTable
                            customerProfile={customerProfile}
                            contracts={activeContracts}
                            paymentInstruments={paymentInstruments}
                            onShowSelectPromotionModal={this.showSelectPromotionModal}
                            onShowCancelModal={this.showCancelSubscriptionModal}
                            onShowDeletePaymentInstrumentModal={this.showDeletePaymentInstrumentModal}
                            onShowUpdateUserEmailModal={this.showUpdateCustomerModal}
                            onShowDeleteUserModal={this.showDeleteUserModal}
                        />
                    </TabPane>
                    <TabPane tabId={TabIds.history}>
                        <CustomerWatchlistTable watchlist={watchlist} />
                    </TabPane>
                    <TabPane tabId={TabIds.beacon}>
                        <CustomerWatchlistBeaconTable watchlist={watchlistBeacon} />
                    </TabPane>
                </TabContent>
            </div>
        );
    }

    public render(): JSX.Element {
        const {
            isLoading,
            alertMessage,
            customerProfile,
            isPromotionModalVisible,
            isCancelModalVisible,
            isPaymentInstrumentModalVisible,
            isUpdateCustomerModalVisible,
            selectedContractId,
            paymentInstruments,
            isDeleteUserModalVisible,
        } = this.state;

        return (
            <div className="csr-tool-page">
                <Title content={STRINGS.PAGE_TITLE} />
                <FindContractsForm submitHandler={this.getTableData} disabled={isLoading} />

                {isLoading && <Spinner color="primary" />}
                {alertMessage && this.renderAlert()}

                {!isLoading && customerProfile && this.makeTabs()}

                <PromotionModal applyPromotionToContract={this.applyPromotionToContract} showModal={isPromotionModalVisible} setModalVisibility={this.showSelectPromotionModal} />

                {selectedContractId && isCancelModalVisible && (
                    <CancelSubscriptionModal
                        onHide={(): void => this.setState({ isCancelModalVisible: false })}
                        contractId={selectedContractId}
                        onCancelContract={this.onConfirmContractCancellation}
                    />
                )}

                {paymentInstruments && isPaymentInstrumentModalVisible && (
                    <CancelPaymentInstrumentModal
                        paymentInstruments={paymentInstruments}
                        onHide={(): void => this.setState({ isPaymentInstrumentModalVisible: false })}
                        onDeletePaymentInformation={this.onConfirmPaymentInstrumentCancellation}
                    />
                )}

                {customerProfile && isUpdateCustomerModalVisible && (
                    <UpdateCustomerModal
                        customerProfile={customerProfile}
                        setModalVisibility={this.showUpdateCustomerModal}
                        showModal={isUpdateCustomerModalVisible}
                        updateUserProfile={this.updateUserProfile}
                    />
                )}
                {customerProfile && customerProfile.email && isDeleteUserModalVisible && (
                    <DeleteUserProfileModal onConfirm={this.deleteUserProfile} userEmail={customerProfile.email} setModalVisibility={this.showDeleteUserModal} />
                )}
            </div>
        );
    }
}

export default withRouter(withPermission(CSRTool, AvailableRoutesEnum.CSR_TOOL));
