import axios, { AxiosInstance } from "axios";

import Util from "../../helpers/Util";
import { usePermissionStore } from "../../store/usePermissionStore";
import { Credentials } from "./Requests";
// import { listUserGroups } from "./CognitoService";

class AuthService {
    protected service: AxiosInstance;
    protected store;

    public constructor() {
        this.service = axios.create({
            baseURL: Util.getAPIBase(),
            headers: {
                "Content-type": "application/json",
            },
        });
        this.store = usePermissionStore;
    }

    public async login(req: Credentials): Promise<void> {
        try {
            const res = await this.service.post("/login", req);
            localStorage.setItem("auth-token", res.data.token);
            localStorage.setItem("permissions", res.data.role);
            this.store.getState().setRole(res.data.role);
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getLoginStatus(token: string): Promise<any> {
        try {
            const res = await this.service.get(`/login?token=${token}`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async logout(token: string): Promise<any> {
        try {
            const res = await this.service.post(`/logout?token=${token}`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new AuthService();
