import React, { ChangeEvent, PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Col, Form, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Spinner, UncontrolledAlert } from "reactstrap";
import ProgramService from "../../services/PureFlixAdmin/ProgramService";
import ProgramsTable from "./ProgramsTable";
import { Program } from "./Types";

import { AvailableRoutesEnum } from "../../config/permissions";
import { withPermission } from "../../hoc/withPermission";
import RestrictionService from "../../services/PureFlixAdmin/RestrictionService";
import Title from "../Title";
import Util from "../../helpers/Util";

enum STRINGS {
    PAGE_TITLE = "Program Search",
}

type AlertType = "danger" | "warning" | "success";

type PropsType = RouteComponentProps;

interface State {
    alertMessage: string;
    alertType: AlertType;
    programList: Program[];
    isLoading: boolean;
    isModalLoading: boolean;
    currentPage: number;
    numberOfPages: number;
    title: string;
    programType: string;
    tags: string[];
    tagsText: string;
    queryString: string;
    restrictions: string[];
}

class Programs extends PureComponent<PropsType, State> {
    private timer: undefined | any;
    private account: string;
    private _isMounted;
    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            programList: [],
            title: "",
            isLoading: true,
            isModalLoading: false,
            currentPage: 1,
            numberOfPages: 1,
            programType: "movie|series",
            tagsText: "",
            tags: [],
            queryString: " ",
            restrictions: [],
        };
        this._isMounted = false;
        this.timer = undefined;
        this.account = Util.getOwnerId();
    }

    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.updateSpinnerStatus(!this.state.isLoading);
            this.getAllRestrictions();
        }
    }
    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private async getAllRestrictions(): Promise<void> {
        const { restrictions } = await RestrictionService.getRestrictions();
        if (this._isMounted) {
            this.setState({ restrictions });
        }
    }

    private updateSpinnerStatus(show: boolean): void {
        this.setState({ isLoading: show });
    }

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private renderAlert(): JSX.Element {
        return (
            <UncontrolledAlert color={this.state.alertType} className="alert">
                {this.state.alertMessage}
            </UncontrolledAlert>
        );
    }

    private handleInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            title: event.target.value,
            queryString: `title.loose:"${event.target.value}"^8 pureflix$keywords:"${event.target.value}"^4 credits.personName.caseInsensitive:"${event.target.value}"^3 displayGenre.caseInsensitive:"${event.target.value}"^2 description.loose:"${event.target.value}"^1`,
        });
    };

    private handleSelect = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ programType: event.target.value });
    };

    private handleSelectTag = (event: ChangeEvent<HTMLInputElement>): void => {
        clearTimeout(this.timer);

        const value = event.target.value;

        this.setState({ tagsText: value });

        this.timer = setTimeout(() => {
            const { tagsText, tags } = this.state;

            if (tagsText && !tags.includes(tagsText.trim())) {
                this.setState({ tags: [...tags, value.trim()], tagsText: "" });
            }
        }, 1500);
    };

    private removeTag = (tag: string) => {
        const tags = this.state.tags.filter((i) => i !== tag);

        this.setState({ tags: tags });
    };

    private submitSearch = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        this.setState({
            currentPage: 1,
            programList: [],
        });

        try {
            await this.submitHandler(1);
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.setAlert(error.message.replace("Error: ", ""), "warning");
            } else {
                this.setAlert("Unknown error in Programs.tsx:submitSearch");
            }
        }
    };

    private createSeasonsArray(seasonsNumber: number): (Program[] | null)[] {
        const seasons: Program[][] = Array.from({ length: seasonsNumber + 1 }, () => []);

        return seasons;
    }
    private getAllSeasons = async (item: Program, seasonsNumber: number): Promise<Program[][]> => {
        try {
            const seasons = this.createSeasonsArray(seasonsNumber);
            const seriesId = item.id.split("/").pop();

            const { programs } = await ProgramService.getAllPrograms({ seriesId });
            programs.forEach((episode) => {
                const seas: number = episode.tvSeasonNumber;
                // Object.assign(seasons, {
                //     [seas]: [...seasons[seas], episode]
                // });
                const adjustedSeas = seas - 1; // Adjust the season number to match the array index
                if (adjustedSeas >= 0 && adjustedSeas < seasons.length && Array.isArray(seasons[adjustedSeas])) {
                    seasons[adjustedSeas] = [...(seasons[adjustedSeas] || []), episode];
                } else {
                    console.error(`Invalid season number ${seas} for episode ${episode.id}`);
                }
            });

            return seasons;
        } catch (error) {
            // console.log(error);
            return [];
        }
    };

    private getPrograms = async (page: number) => {
        this.setAlert("");
        const { programType, tags, queryString } = this.state;
        let { title } = this.state;

        if (!title && tags.length) {
            title = " ";
        }
        const { programs, numberOfPages } = await ProgramService.getAllPrograms({ page, programType, q: queryString });

        if (programs.length === 0) {
            throw new Error("NO TITLE ON THE PLATFORM MATCHES THE SEARCH REQUEST");
        }

        this.setState({
            programList: programs,
            numberOfPages,
            isModalLoading: false,
        });
    };

    private submitHandler = async (index: number) => {
        try {
            this.updateSpinnerStatus(!this.state.isLoading);
            await this.getPrograms(index);
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.setAlert(error.message.replace("Error: ", ""), "warning");
            } else {
                this.setAlert("Unknown error in Programs.tsx:submitHandler");
            }
        } finally {
            this.updateSpinnerStatus(!this.state.isLoading);
        }
    };

    private async handlePaginationClick(e: any, index: number) {
        try {
            e.preventDefault();
            this.setState({
                isModalLoading: true,
                currentPage: index,
            });

            await this.getPrograms(index);
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.setAlert(error.message.replace("Error: ", ""), "warning");
            } else {
                this.setAlert("Unknown error in Programs.tsx:handlePaginationClick");
            }
        } finally {
            this.setState({ isModalLoading: false });
        }
    }

    private getPaginatedWindow = (totalPages: number, currentPage: number, windowSize = 10) => {
        let startPage, endPage;
        if (totalPages <= windowSize) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const halfWindowSize = Math.floor(windowSize / 2);
            if (currentPage <= halfWindowSize) {
                startPage = 1;
                endPage = windowSize;
            } else if (currentPage + halfWindowSize >= totalPages) {
                startPage = totalPages - windowSize + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - halfWindowSize;
                endPage = currentPage + halfWindowSize;
            }
        }
        const res = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        return res;
    };
    private showResults(programList: Program[]): JSX.Element {
        const { currentPage, numberOfPages, isModalLoading } = this.state;

        const PaginationForm = (): JSX.Element => {
            const paginationWindow = this.getPaginatedWindow(numberOfPages, currentPage);
            return (
                <>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink onClick={(e) => this.handlePaginationClick(e, 1)} first href="#" />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink onClick={(e) => this.handlePaginationClick(e, currentPage - 1)} previous href="#" />
                        </PaginationItem>

                        {paginationWindow.map((page) => (
                            <PaginationItem active={page === currentPage} key={page}>
                                <PaginationLink onClick={(e) => this.handlePaginationClick(e, page)} href="#">
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === numberOfPages}>
                            <PaginationLink onClick={(e) => this.handlePaginationClick(e, currentPage + 1)} next href="#" />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === numberOfPages}>
                            <PaginationLink onClick={(e) => this.handlePaginationClick(e, numberOfPages)} last href="#" />
                        </PaginationItem>
                    </Pagination>
                </>
            );
        };
        const ownersMedias = (item: Program) => {
            const filteredMediaByOwner = item.media.filter((el) => el.ownerId === this.account);
            return filteredMediaByOwner;
        };

        return (
            <>
                {programList.length > 0 && (
                    <div className="pagination-form">
                        <PaginationForm />
                        {isModalLoading && <Spinner color="primary" />}

                        {programList.map((program) =>
                            ownersMedias(program).length ? (
                                <div className="data-slice results-table" key={program.title}>
                                    <ProgramsTable key={program.id} item={program} getAllSeasons={this.getAllSeasons} restrictions={this.state.restrictions} />
                                </div>
                            ) : null
                        )}

                        {!isModalLoading && <PaginationForm />}
                    </div>
                )}
            </>
        );
    }

    public render(): JSX.Element {
        const { title, programType, alertMessage, isLoading, programList } = this.state;

        return (
            <div className="programs-page container-fluid">
                <Title content={STRINGS.PAGE_TITLE} />
                <Form disabled={isLoading} onSubmit={this.submitSearch} className="programs-form">
                    <fieldset>
                        <Row>
                            <Col sm={6}>
                                <Label for="program-type">Title</Label>
                                <Input name="inputText" label="title" id="inputText" onChange={this.handleInputValueChange} value={title} />
                            </Col>
                            <Col>
                                <Label for="program-type">Program Type</Label>
                                <Input type="select" name="program-type" id="program-type" value={programType} onChange={this.handleSelect}>
                                    <option value="movie|series">Movies / Series</option>
                                    <option value="movie">Movies</option>
                                    <option value="series">Series</option>
                                    <option value="episode">Episodes</option>
                                </Input>
                            </Col>
                            <Col sm={1}>
                                <Button color="primary" className="programs-button">
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </fieldset>
                </Form>

                {isLoading && <Spinner color="primary" />}
                {alertMessage && this.renderAlert()}

                {!isLoading && programList && this.showResults(programList)}
            </div>
        );
    }
}

export default withRouter(withPermission(Programs, AvailableRoutesEnum.PROGRAMS));
