import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";

import Login from "./components/Auth/Login";
import Logout from "./components/Logout";
import Content from "./components/Content";
import { routes } from "./routes";
import AuthHelper from "./helpers/AuthHelper";
import Forbidden from "./components/Forbidden";
import { usePermissionStore } from "./store/usePermissionStore";
import { RoleEnum, permissions } from "./config/permissions";

interface State {
    role: RoleEnum;
}
type AppProps = unknown;
class App extends Component<AppProps, State> {
    private unsubscribe: () => void;

    private constructor(props: AppProps) {
        super(props);
        this.state = {
            role: usePermissionStore.getState().role as RoleEnum,
        };
        this.unsubscribe = usePermissionStore.subscribe((state) => {
            this.setState({ role: state.role as RoleEnum });
        });
    }

    private blockRoute(path: any): boolean {
        const allow = permissions[this.state.role as RoleEnum].includes(path);
        return !allow;
    }
    private getRoutes(): JSX.Element[] {
        return routes.map(({ path, component }, key): JSX.Element => {
            // const isCSR = AuthHelper.isCSR();
            // const isGifts = AuthHelper.isGifts();
            let comp: JSX.Element;
            if (this.blockRoute(path)) {
                comp = <Forbidden />;
            } else {
                comp = component;
            }
            return (
                <Route
                    exact
                    path={path}
                    key={key}
                    render={(): Promise<JSX.Element> => (AuthHelper.isTokenSet() ? comp : <Redirect to={AuthHelper.getRootPathWithNotAllowedMsg()} />)}
                />
            );
        });
    }

    public componentWillUnmount(): void {
        this.unsubscribe();
    }

    public render(): JSX.Element {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/logout" component={Logout} />
                    {AuthHelper.isTokenSet() ? <Content>{this.getRoutes()}</Content> : <Redirect to={AuthHelper.getRootPathWithNotAllowedMsg()} />}
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
