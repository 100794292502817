import axios, { AxiosInstance } from "axios";
import { Response } from "../../components/Programs/Types";
import Util from "../../helpers/Util";

interface ProgramRequest {
    page?: number;
    programType?: string;
    seriesId?: string;
    byOwnerId?: string;
    q?: string;
}

class ProgramService {
    private programsEndpoint = "/programs";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getAllPrograms(req: ProgramRequest): Promise<Response> {
        try {
            const config = {
                params: req,
            };

            const { data } = await this.service.get(this.programsEndpoint, config);

            return {
                numberOfPages: data.numberOfPages,
                programs: data.programs,
            };
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new ProgramService();
