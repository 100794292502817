import React, { ChangeEvent } from "react";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Alert, InputGroup, InputGroupAddon } from "reactstrap";
import ContractsService, { cancelContractPayload } from "../../services/PureFlixAdmin/ContractService";

interface Props {
    contractId: string;
    onHide: () => void;
    onCancelContract: (contractId: string, payload: cancelContractPayload) => Promise<void>;
}

interface State {
    selectedOption?: CancelOptionConfig;
    isLoading: boolean;
    fetchingRefundAmount: boolean;
    refundAmount: number;
    refundFullAmount: number;
    fulfillmentItemRef: string;
    refundAmountInput: number;
    alertMessage?: string;
}

interface CancelOptionConfig {
    key: string;
    title: string;
    description: string;
    params: {
        atNextAction: boolean;
        refund: boolean;
    };
}

enum CancelOptionKeys {
    CANCEL_NO_REFUND_NEXT_BILL = "CANCEL_NO_REFUND_NEXT_BILL",
    CANCEL_FULL_REFUND_IMMEDIATE = "CANCEL_FULL_REFUND_IMMEDIATE",
    CANCEL_PRORATED_REFUND_IMMEDIATE = "CANCEL_PRORATED_REFUND_IMMEDIATE",
}

const cancelOptions: CancelOptionConfig[] = [
    {
        key: CancelOptionKeys.CANCEL_NO_REFUND_NEXT_BILL,
        title: "Set to Cancel - Next Bill",
        description: "Cancel the customer's contract before the next billing date",
        params: {
            atNextAction: true,
            refund: false
        }
    },
    {
        key: CancelOptionKeys.CANCEL_FULL_REFUND_IMMEDIATE,
        title: "Cancel Immediately - Full Refund",
        description: "Cancel the customer's contract immediately with a full refund",
        params: {
            atNextAction: false,
            refund: false
        }
    },
    {
        key: CancelOptionKeys.CANCEL_PRORATED_REFUND_IMMEDIATE,
        title: "Cancel Immediately - Prorated Refund",
        description: "Cancel the customer's contract immediately with a prorated refund",
        params: {
            atNextAction: false,
            refund: false
        }
    }
];

export default class CancelSubscriptionModal extends React.PureComponent<Props, State> {

    private constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            fetchingRefundAmount: false,
            refundAmount: 0,
            refundFullAmount: 0,
            refundAmountInput: 0,
            fulfillmentItemRef: ''
        };
    }

    public async componentDidMount(): Promise<void> {
        this.setState({ fetchingRefundAmount: true });
        const { amount, fullAmount, fulfillmentItemRef } = await ContractsService.getRefundAmount(this.props.contractId);
        this.setState({
            fetchingRefundAmount: false,
            refundAmount: amount,
            refundAmountInput: amount,
            refundFullAmount: fullAmount,
            fulfillmentItemRef: fulfillmentItemRef || ''
        });
    }

    private onSelectOption = (option: CancelOptionConfig): void => {
        this.setState({ selectedOption: option });
    }

    private onRefundAmountChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value ? parseFloat(event.target.value) : "";
        this.setState({ refundAmountInput: value as number });
    };

    private isProratedRefundKey = (key: string): boolean => {
        return key === CancelOptionKeys.CANCEL_PRORATED_REFUND_IMMEDIATE;
    }

    private isFullRefundKey = (key: string): boolean => {
        return key === CancelOptionKeys.CANCEL_FULL_REFUND_IMMEDIATE;
    }

    private isRefundKey = (key: string): boolean => {
        return this.isProratedRefundKey(key) || this.isFullRefundKey(key);
    }

    private onSubmitCancellation = async (): Promise<void> => {
        const { selectedOption, refundFullAmount, refundAmountInput, fulfillmentItemRef } = this.state;
        const { onHide, onCancelContract, contractId } = this.props;
        if (!selectedOption) {
            return;
        }
        const { atNextAction, refund } = selectedOption.params;
        this.setState({ isLoading: true, alertMessage: undefined });

        const payload: cancelContractPayload = {
            refund,
            atNextAction
        };

        const isProratedRefund = this.isProratedRefundKey(selectedOption.key);
        const isFullRefund = this.isFullRefundKey(selectedOption.key);

        if (isFullRefund || isProratedRefund) {
            payload.refundAmount = isFullRefund ? refundFullAmount : refundAmountInput;
            payload.fulfillmentItemRef = fulfillmentItemRef;
        }

        try {
            await onCancelContract(contractId, payload);
            onHide();
        } catch (error: unknown) {
            let errorMessage = "";
            if (error instanceof Error) {
                errorMessage = error.message;
            }
            else {
                errorMessage = "Unknown error in CancelPaymentInstrumentModal.tsx:onSubmitCancellation";
            }
            this.setState({
                isLoading: false,
                alertMessage: errorMessage
            });
        }
    }

    public render(): JSX.Element {
        const { onHide } = this.props;
        const { selectedOption, isLoading, alertMessage, fetchingRefundAmount, refundAmount, refundFullAmount, fulfillmentItemRef, refundAmountInput } = this.state;
        const isInvalidRefundAmount = selectedOption && this.isProratedRefundKey(selectedOption.key) && (refundAmountInput > refundAmount || !refundAmountInput);
        const options = cancelOptions.map((option, i) => {

            const isRefundOption = this.isRefundKey(option.key);
            const isProratedRefundOption = this.isProratedRefundKey(option.key);
            const isFullRefundOption = this.isFullRefundKey(option.key);

            if (isRefundOption) {
                if (fetchingRefundAmount && isProratedRefundOption) return (<Spinner color="primary" size="sm" key={i} />);
                if (refundAmount <= 0 || !fulfillmentItemRef) return;
            }

            return (
                <FormGroup check key={i}>
                    <Label check>
                        <Input type="radio" name="cancelOption" onChange={(): void => this.onSelectOption(option)} />
                        <strong>{option.title} {isRefundOption ? `($${isFullRefundOption ? refundFullAmount : refundAmount})` : ""}</strong>
                        <div><em>{option.description}</em></div>

                        {
                            isProratedRefundOption &&
                            <InputGroup className="refund-input">
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                    name="refund-amount"
                                    disabled={!selectedOption || !this.isProratedRefundKey(selectedOption.key)}
                                    type="number"
                                    min={1}
                                    max={refundAmount}
                                    value={refundAmountInput}
                                    onChange={this.onRefundAmountChange}
                                />
                            </InputGroup>
                        }

                    </Label>
                </FormGroup>
            );
        });

        return (
            <Modal isOpen={true} size="lg" toggle={onHide}>
                <ModalHeader toggle={onHide}>
                    Select Cancelation Method</ModalHeader>
                <Form id="cancel-subscription-form" className="cubo-form">
                    <ModalBody>
                        <div>
                            {alertMessage && (
                                <Alert color="warning" className="alert" style={{ minWidth: "100%" }}>{alertMessage}</Alert>
                            )}
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-10">
                                {options}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onSubmitCancellation} color="danger" disabled={!selectedOption || isLoading || isInvalidRefundAmount}>{isLoading ? (<Spinner size="sm" color="white" />) : "Confirm"}</Button>
                        <Button onClick={onHide} color="primary" disabled={isLoading}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }

}
