import React from "react";
import { Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { Spinner } from 'reactstrap';
import { Program } from "./Types";

interface State {
    details: Program | undefined;
    isSeasonDropdownOpen: boolean;
}
interface Props {
    showDetailsModal: boolean;
    isModalLoading: boolean;
    item: Program;
    seasons: Program[][];
    setModalVisibility: (show: boolean) => void;
    generateDetailsTable: (item: Program) => JSX.Element;
}

class DetailsModal extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            details: undefined,
            isSeasonDropdownOpen: false
        };
    }

    private onToggleModal = (): void => {
        this.props.setModalVisibility(!this.props.showDetailsModal);
    }

    private setDetailsData = (details: Program) => {
        this.setState({ details });
    }

    private dropdownToggle = () => {
        this.setState({ isSeasonDropdownOpen: !this.state.isSeasonDropdownOpen });
    }

    public render(): JSX.Element {
        const { showDetailsModal} = this.props;
        
        return (
            <>
                <Modal isOpen={showDetailsModal} size="lg">
                    <ModalHeader toggle={this.onToggleModal}>
                        Details
                        </ModalHeader>
                    {this.props.isModalLoading && <Spinner
                        color="primary"
                        className="spinner"
                    />}
                    {!this.props.isModalLoading && (
                        <Form id="category-edit-form" className="cubo-form">
                            <ModalBody>
                                {this.props.item && this.props.generateDetailsTable(this.props.item)}
                            </ModalBody>
                        </Form>
                    )}
                </Modal>
            </>
        );
    }
}

export default DetailsModal;
