import React from "react";
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface Props {
    customerProfile: Record<string, string>;
    showModal: boolean;
    updateUserProfile: (id: string, payload: Record<string, string>) => Promise<void>;
    setModalVisibility: (show: boolean) => void;
}

interface State {
    userEmail: string;
    errorMsg?: string;
    isLoading: boolean;
}

export default class UpdateCustomerModal extends React.PureComponent<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            userEmail: props.customerProfile.email,
            isLoading: false
        };
    }

    private onFormSubmit = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent): Promise<boolean> => {
        const { customerProfile: userProfile, updateUserProfile } = this.props;
        const { userEmail: email } = this.state;

        event.preventDefault();

        try {
            this.setState({ isLoading: true, errorMsg: undefined });
            await updateUserProfile(userProfile.id, { newEmail: email.trim(), currentEmail: this.props.customerProfile.email });
        } catch (e: unknown) {
            let errorMessage = "";
            if (e instanceof Error) {
                errorMessage = errorMessage + e.message;
            }
            else {
                errorMessage = "Unknown error in UpdateCustomerModal.tsx:onFormSubmit";
            }
            this.setState({
                isLoading: false,
                errorMsg: errorMessage
            });
        }
        return false;
    }

    private onToggleModal = (): void => {
        this.props.setModalVisibility(!this.props.showModal);
    }

    private onChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ userEmail: evt.target.value });
    }

    public render(): JSX.Element {
        const { showModal } = this.props;
        const { userEmail, errorMsg, isLoading } = this.state;
        return (
            <Modal isOpen={showModal} size="md">
                <ModalHeader toggle={this.onToggleModal}>
                    Update User Profile</ModalHeader>
                <Form id="category-edit-form" className="cubo-form" onSubmit={this.onFormSubmit}>
                    <ModalBody>
                        {errorMsg && (
                            <Alert color="warning" className="width--full">
                                {errorMsg}
                            </Alert>
                        )}
                        <div className="form-group row">
                            <Label htmlFor="userEmail" className="col-sm-2 col-form-label">New Email</Label>
                            <div className="col-sm-10">
                                <Input type="text" name="userEmail" value={userEmail} onChange={this.onChange} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onFormSubmit} color="primary" disabled={!userEmail || isLoading}>Save</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }

}
