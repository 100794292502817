import React from "react";
import { Alert, Button, Spinner, Table } from "reactstrap";
import { RoleEnum, formatRoleName, permissions, roles } from "../../config/permissions";
import UserService, { User } from "../../services/PureFlixAdmin/UserService";
import Title from "../Title";
import RoleModal from "./RoleModal";

const STRINGS: Record<string, string> = {
    PAGE_TITLE: "Roles",
    ROLE: "Role",
    NO_ROLE: "No roles were found",
    REMOVE_USER_MODAL_TITLE: "Removal Confirmation",
    CREATE_NEW: "Create",
    UPDATE_SUCCESS: "User updated Successfully",
    CREATE_SUCCESS: "User created Successfully",
    DELETE_CONFIRMATION: "Do you want to remove the selected user",
    DELETE_SUCCESS: "User deleted successfully",
};

interface State {
    isLoading: boolean;
    alertMessage?: string;
    input: string;
    selectedRole: RoleEnum;
    isRoleModalVisible: boolean;
    isConfirmationModalVisible: boolean;
    allUsers: User[];
    roles: RoleEnum[];
}

export default class Roles extends React.Component<Record<string, any>, State> {
    private constructor(props: Record<string, any>) {
        super(props);
        this.state = {
            isLoading: false,
            input: "",
            isRoleModalVisible: false,
            isConfirmationModalVisible: false,
            allUsers: [],
            roles: roles,
            selectedRole: RoleEnum.UNDEFINED,
        };
    }

    public componentDidMount(): void {
        this.getAllUsers();
    }

    private showUsersByRole(role: RoleEnum | string): number {
        const users = this.state.allUsers.filter((item) => item.role === role);
        return users.length;
    }

    private getAllUsers = async (): Promise<void> => {
        try {
            const allUsers = await UserService.getAll();
            this.setState({ allUsers, isLoading: false });
        } catch (error: unknown) {
            if (error instanceof Error) {
                // console.log("All Users Error: ", error.message);
            } else {
                console.log("Caught unknown error in retrieving available users.");
            }
        }
    };

    private onViewRole = (role: RoleEnum): void => {
        this.setState({ selectedRole: role, isRoleModalVisible: true });
    };

    private onHideRoleModal = (): void => {
        this.setState({
            isRoleModalVisible: false,
        });
    };

    private closeConfirmationModal = (): void => {
        this.setState({ isConfirmationModalVisible: false, selectedRole: RoleEnum.UNDEFINED });
    };

    private RolesTable = (): JSX.Element => {
        const { roles, isLoading } = this.state;

        if (isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }
        if (!roles) {
            return <></>;
        }

        if (roles.length === 0) {
            return (
                <div>
                    <Alert color="primary">{STRINGS.NO_ROLE}</Alert>
                </div>
            );
        }

        const Header = (): JSX.Element => (
            <tr>
                <th>Role</th>
                <th>Total Users</th>
                <th>Permissions</th>
            </tr>
        );

        const Body = (): JSX.Element => {
            return (
                <>
                    {roles.map(
                        (role: RoleEnum, index: number): JSX.Element => (
                            <tr key={index}>
                                <td>{formatRoleName(role)}</td>
                                <td>{this.showUsersByRole(role)}</td>
                                <td>
                                    <Button onClick={(): void => this.onViewRole(role)} className="btn" color="primary" style={{ marginRight: "5px" }}>
                                        View
                                    </Button>
                                </td>
                            </tr>
                        )
                    )}
                </>
            );
        };

        return (
            <Table>
                <thead>
                    <Header />
                </thead>
                <tbody>
                    <Body />
                </tbody>
            </Table>
        );
    };

    public render(): JSX.Element {
        const { selectedRole, isRoleModalVisible, alertMessage } = this.state;
        return (
            <div className="csr-tool-page">
                <Title content={STRINGS.PAGE_TITLE} />

                {/* {this.Form()} */}
                <div>
                    {alertMessage && (
                        <Alert
                            color="success"
                            toggle={(): void => {
                                this.setState({ alertMessage: undefined });
                            }}
                        >
                            {alertMessage}
                        </Alert>
                    )}
                </div>
                <div style={{ marginTop: "15px" }}>
                    <this.RolesTable />
                </div>
                {isRoleModalVisible && <RoleModal selectedRole={selectedRole} onHide={this.onHideRoleModal} permissions={permissions} />}
            </div>
        );
    }
}
