import { Component } from "react";
import { Link } from "react-router-dom";
import { usePermissionStore } from "../../store/usePermissionStore";
import packageCfg from "../../../package.json";
import Util from "../../helpers/Util";

class Header extends Component {
    private state = {
        role: usePermissionStore.getState().role,
    };

    public render(): JSX.Element {
        const isProduction = Util.cleanEnvString(process.env.NODE_ENV) === "production";
        return (
            <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
                <Link to={"/task-runner"} className="navbar-brand col-sm-3 col-md-2 mr-0">
                    Pure Flix Admin Portal
                </Link>
                <ul className="header-navbar" style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                    <div
                        style={{
                            width: 5,
                            height: 5,
                            backgroundColor: isProduction ? "green" : "red",
                            borderRadius: "50%",
                            marginRight: 10,
                        }}
                    />
                    <li id="versions">
                        <strong>Version:</strong> v{packageCfg.customVersion}
                    </li>
                    <li id="role">
                        <strong>Role:</strong> {this.state.role}
                    </li>
                    <li>
                        <Link to={"/logout"}>Sign out</Link>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Header;
