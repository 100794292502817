import { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledAlert } from "reactstrap";
import { AvailableRoutesEnum, PlansEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { ModalFormType } from "../../helpers/ModalFormType";
import { withPermission } from "../../hoc/withPermission";
import PlansService, { Buttons, PlansConfig } from "../../services/PureFlixAdmin/PlansService";
import Title from "../Title";
import PlansAddConfigForm from "./PlansAddConfigForm";
import PlansConfigTable from "./PlansConfigTable";
import PlansPreviewPanel from "./PlansPreviewPanel";

type AlertType = "danger" | "warning" | "success";

type PropsType = RouteComponentProps;

interface State {
    alertMessage: string;
    alertType: AlertType;
    isConfigListLoading: boolean;
    isPreviewLoading: boolean;
    isPreviewModalOpen: boolean;
    expectedPreviewPlansCount: number;
    configList: PlansConfig[];
    selectedPlansConfigForPreview: PlansConfig;
    selectedPlansConfig: PlansConfig;
    selectedConfigButton: Buttons;
    previewResponse: any;
    showModalForm: boolean;
    modalConfirmation: boolean;
    showModalButton: boolean;
    modalFormType: ModalFormType;
    indexButton: number;
    indexConfig: number;
}

enum STRINGS {
    PAGE_TITLE = "Plans",
}

const GuardPlanButton = withPermission(Button, PlansEnum.ADD_PAGE);

class Plans extends PureComponent<PropsType, State> {
    private _isMounted;

    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            isConfigListLoading: true,
            isPreviewLoading: true,
            configList: [],
            selectedPlansConfigForPreview: {
                pageCode: "default",
                buttons: [
                    {
                        buttonCode: "test",
                        filters: "",
                    },
                ],
            },
            selectedConfigButton: {
                buttonCode: "test",
                filters: "",
            },
            selectedPlansConfig: {
                pageCode: "",
                buttons: [
                    {
                        buttonCode: "",
                        filters: "",
                    },
                ],
            },
            previewResponse: [],
            modalConfirmation: false,
            showModalForm: false,
            showModalButton: false,
            modalFormType: ModalFormType.ADD,
            indexButton: 0,
            indexConfig: 0,
            isPreviewModalOpen: false,
            expectedPreviewPlansCount: 0,
        };
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.getAll();
        }
    }
    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            } else {
                this.props.history.push("/", { message: "Unknown error in Plans.tsx:checkLoggedIn" });
            }
            return false;
        }
    };

    private async getAll(): Promise<void> {
        try {
            const isLoggedIn = await this.checkLoggedIn();
            if (isLoggedIn && this._isMounted) {
                this.setState({ isConfigListLoading: true, isPreviewLoading: true });
                const configList = await PlansService.getAll();
                this.setState({
                    isConfigListLoading: false,
                    configList,
                });
            }
        } catch (err) {
            this.setError(new Error("There was an error while fetching the plans configuration"));
        }
    }

    private getPlansFromMPX = async (pageCode: string, button: Buttons): Promise<void> => {
        this.setState({
            alertMessage: "",
            isPreviewLoading: true,
            previewResponse: [],
            selectedPlansConfigForPreview: {
                pageCode,
                buttons: [
                    {
                        buttonCode: button.buttonCode,
                        filters: button.filters,
                    },
                ],
            },
            isPreviewModalOpen: true,
        });
        const expectedPreviewPlansCount = this.getExpectedPlansPreviewAmount(button.filters);
        const previewResponse = await PlansService.getPlansFromMPX(button.filters);
        this.setState({ isPreviewLoading: false, previewResponse, expectedPreviewPlansCount });
    };

    private getExpectedPlansPreviewAmount = (filter: string): number => {
        const countryWildcard = "[country]";
        const trialWildcard = "[trialEligible]";
        const hasFreeTrialWildcard = filter.includes(trialWildcard);
        const hasCountryWildcard = filter.includes(countryWildcard);

        return hasCountryWildcard && hasFreeTrialWildcard ? 4 : 2;
    };

    private setError = (err: any, alertType: AlertType = "danger"): void => {
        const alertMessage = err.response ? err.response.data.message : err.message;
        this.setAlert(alertMessage, alertType);
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private renderAlert(): JSX.Element {
        return (
            <UncontrolledAlert color={this.state.alertType} className="alert">
                {this.state.alertMessage}
            </UncontrolledAlert>
        );
    }

    private onConfirmDelete = (): void => {
        const configList = [...this.state.configList];
        const configListIndex = configList.findIndex((plan) => plan.pageCode === this.state.selectedPlansConfig.pageCode);

        this.deleteHandler(configList, configListIndex);
        this.closeConfirmationModal();
    };

    private deleteHandler = async (configList: PlansConfig[], configListIndex: number): Promise<void> => {
        // if indexConfig is present and above 0 (default, set as init state) means it has to delete config and update configlist
        if (this.state.indexConfig > 0) {
            configList.splice(configListIndex, 1);
            await this.updateConfigList(configList);
        }
        // in other cases it will delete/update the single plans in each config
        else {
            configList[configListIndex].buttons.splice(this.state.indexButton, 1);
            await this.updateConfigList(configList);
        }
    };

    private openConfirmationModal = (config: PlansConfig, indexButton?: number, indexConfig?: number): void => {
        if (config.pageCode === "default") {
            this.setError(new Error("The default code cannot be deleted"));
            return;
        }

        if (indexButton) {
            this.setState({ indexButton });
        }

        if (indexConfig) {
            this.setState({ indexConfig });
        }

        this.setState({
            modalConfirmation: !this.state.modalConfirmation,
            alertMessage: "",
            selectedPlansConfig: config,
        });
    };

    private closeConfirmationModal = (): void => {
        this.setState({
            modalConfirmation: !this.state.modalConfirmation,
            alertMessage: "",
        });
    };

    private updatePlans = (button: Buttons, indexPlan: number, indexButton: number, modalFormType: string): void => {
        const { configList } = this.state;
        if (modalFormType === ModalFormType.ADD) {
            configList[indexPlan].buttons.push(button);
        } else {
            const { buttonCode, filters } = button;
            configList[indexPlan].buttons[indexButton] = {
                buttonCode,
                filters,
            };
        }
        this.updateConfigList(configList);
    };

    private async updateConfigList(configList: PlansConfig[]): Promise<void> {
        this.setState({ isConfigListLoading: true });
        await PlansService.update(configList);
        this.setState({
            configList,
            indexButton: 0,
            isConfigListLoading: false,
        });
    }

    private saveConfig = (planConfig: PlansConfig): void => {
        this.setState({
            showModalForm: !this.state.showModalForm,
        });
        const { configList } = this.state;
        configList.push(planConfig);
        this.updateConfigList(configList);
    };

    private editPlanConfigName = (pageCode: string, indexConfig: number): void => {
        const { configList } = this.state;
        configList[indexConfig].pageCode = pageCode;
        this.updateConfigList(configList);
    };

    private toggleModalAddConfig = (): void => {
        this.setState({
            alertMessage: "",
            modalFormType: ModalFormType.ADD,
            showModalForm: !this.state.showModalForm,
        });
    };

    private togglePreviewModal = (): void => {
        this.setState({
            isPreviewModalOpen: !this.state.isPreviewModalOpen,
        });
    };

    public render(): JSX.Element {
        const {
            alertMessage,
            isConfigListLoading,
            isPreviewLoading,
            expectedPreviewPlansCount: expectedPlansPreviewCount,
            previewResponse,
            selectedPlansConfigForPreview,
            isPreviewModalOpen,
        } = this.state;

        return (
            <div className="plans-page container-fluid">
                <Title content={STRINGS.PAGE_TITLE} />

                <Row>
                    <Col>
                        <GuardPlanButton color="primary" className="add-config" onClick={this.toggleModalAddConfig}>
                            Add Page Config
                        </GuardPlanButton>
                        {alertMessage && this.renderAlert()}
                    </Col>
                </Row>
                <Row>
                    {!this.state.alertMessage ? (
                        <Container fluid>
                            <Row>
                                <Col>
                                    {isConfigListLoading ? (
                                        <Spinner color="primary" />
                                    ) : (
                                        <>
                                            {" "}
                                            {this.state.configList.map((config, index) => (
                                                <PlansConfigTable
                                                    key={index}
                                                    config={config}
                                                    indexConfig={index}
                                                    configList={this.state.configList}
                                                    loadPreview={this.getPlansFromMPX}
                                                    openConfirmationModal={this.openConfirmationModal}
                                                    updatePlans={this.updatePlans}
                                                    editPlanConfigName={this.editPlanConfigName}
                                                />
                                            ))}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    ) : null}
                </Row>
                <Modal isOpen={this.state.modalConfirmation} toggle={this.closeConfirmationModal}>
                    <ModalHeader toggle={this.closeConfirmationModal}>Confirmation</ModalHeader>
                    <ModalBody>Would you like to delete this {this.state.indexConfig > 0 ? "Plan Code" : "Button"}?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onConfirmDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={this.closeConfirmationModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={isPreviewModalOpen} toggle={this.togglePreviewModal} size="xl">
                    <ModalHeader toggle={this.togglePreviewModal}>Filter Preview</ModalHeader>
                    <ModalBody>
                        <PlansPreviewPanel
                            isPreviewLoading={isPreviewLoading}
                            previewResponse={previewResponse}
                            expectedPlansPreviewCount={expectedPlansPreviewCount}
                            selectedPlansConfigForPreview={selectedPlansConfigForPreview}
                        />
                    </ModalBody>
                </Modal>

                <PlansAddConfigForm
                    configList={this.state.configList}
                    showModalForm={this.state.showModalForm}
                    toggleModalAddConfig={this.toggleModalAddConfig}
                    saveConfig={this.saveConfig}
                />
            </div>
        );
    }
}

export default withRouter(withPermission(Plans, AvailableRoutesEnum.PLANS));
