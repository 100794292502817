import { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import AuthHelper from "../helpers/AuthHelper";
import "../styles/main.scss";
import Header from "./Auth/Header";
import Sidebar from "./Sidebar";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

interface State {
    isLoggedIn: boolean;
}

class Content extends PureComponent<PropsType, State> {
    private timeout: NodeJS.Timeout;

    public constructor(props: PropsType) {
        super(props);
        this.timeout = AuthHelper.checkEveryMinuteIfTokenIsValid();
        this.state = {
            isLoggedIn: false,
        };

        this.checkLoggedIn().then((isLoggedIn): void => {
            this.setState({ isLoggedIn });
        });
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            } else {
                this.props.history.push("/", { message: "Unknown error in UpdateCustomerModal.tsx:checkLoggedIn" });
            }
            return false;
        }
    };

    public componentWillUnmount(): void {
        clearInterval(this.timeout);
    }

    public render(): JSX.Element {
        if (this.state.isLoggedIn) {
            return (
                <div id="root">
                    <div className="main">
                        <Header />
                        <div className="container-fluid">
                            <div className="row">
                                <Sidebar />
                                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                                    {this.props.children}
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}

export default withRouter(Content);
