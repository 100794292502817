import bsCustomFileInput from "bs-custom-file-input";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Row, UncontrolledDropdown } from "reactstrap";
import { AccountCleanUpEnum } from "../../config/permissions";
import { withPermission } from "../../hoc/withPermission";
import { AccountCleanupRequest } from "../../services/PureFlixAdmin/Requests";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    disabled: boolean;
    submitHandler: (payload: AccountCleanupRequest) => Promise<any>;
};

interface State extends AccountCleanupRequest {
    fileName: string;
}
const GuardCleanUpButton = withPermission(Button, AccountCleanUpEnum.RUN);
const GuardAccountCleanUpForm = withPermission(Form, AccountCleanUpEnum.VIEW);
class AccountCleanupForm extends Component<PropsType> {
    public state: State;

    public constructor(props: PropsType) {
        super(props);
        this.state = Object.assign(AccountCleanupRequest.getEmptyInstance(), { fileName: "Choose file" });
    }

    public componentDidMount(): void {
        bsCustomFileInput.init();
    }

    private submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            event.preventDefault();
            const { submitHandler } = this.props;

            const req = new AccountCleanupRequest(this.state.cleanupBy, this.state.email, this.state.csvFile);
            await submitHandler(req);
        } catch (err) {
            if (err instanceof Error) {
                // console.log(err.message);
            } else {
                console.log("Unexpected error in AccountCleanupForm.tsx");
            }
        }
    };

    private handleChange = (event: any): void => {
        this.setState({ [event.target.name]: event.target.value });
    };

    private handleFileChange = (event: any): void => {
        const csvFile = event.target.files[0];
        if (csvFile) {
            this.setState({ csvFile, fileName: csvFile.name });
        } else {
            this.setState({ csvFile: null, fileName: "" });
        }
    };

    private setCleanupBy = (cleanupBy: string): void => {
        this.setState({ cleanupBy, fileName: "" });
    };

    public render(): JSX.Element {
        return (
            <GuardAccountCleanUpForm id="account-cleanup-form" onSubmit={this.submitForm}>
                <fieldset disabled={this.props.disabled}>
                    <Row>
                        <Col sm={1}>
                            <UncontrolledDropdown color="primary">
                                <DropdownToggle caret>{this.state.cleanupBy}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Run cleanup by:</DropdownItem>
                                    <DropdownItem onClick={(): void => this.setCleanupBy("E-Mail")}>E-Mail</DropdownItem>
                                    <DropdownItem onClick={(): void => this.setCleanupBy("CSV File")}>CSV File</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col sm={3}>
                            {this.state.cleanupBy === "E-Mail" ? (
                                <Input type="email" name="email" id="email" onChange={this.handleChange} required />
                            ) : (
                                <div className="custom-file">
                                    <Input id="csvFile" type="file" name="csvFile" className="custom-file-input" onChange={this.handleFileChange} required />
                                    <label className="custom-file-label" htmlFor="csvFile">
                                        {this.state.fileName || "Choose file"}
                                    </label>
                                </div>
                            )}
                        </Col>
                        <Col sm={1}>
                            <GuardCleanUpButton color="primary">Cleanup</GuardCleanUpButton>
                        </Col>
                    </Row>
                </fieldset>
            </GuardAccountCleanUpForm>
        );
    }
}

export default withRouter(AccountCleanupForm);
