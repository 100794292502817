export class Credentials {
    public constructor(public username: string, public password: string) {}

    public static getEmptyInstance(): Credentials {
        return new Credentials("", "");
    }
}

export interface GetUserContractsPayload {
    findBy: string;
    inputValue: string;
}

export interface CreateCompAccountPayload {
    firstName: string;
    lastName: string;
    email: string;
    productId: string;
    productTags: string[];
}

export class AccountCleanupRequest {
    public constructor(public cleanupBy: string, public email: string, public csvFile: string | Blob | null) {}

    public static getEmptyInstance(): AccountCleanupRequest {
        return new AccountCleanupRequest("E-Mail", "", null);
    }

    public toFormData(): FormData {
        const data = new FormData();
        data.append("cleanupBy", this.cleanupBy);
        data.append("email", this.email);

        if (this.csvFile) {
            data.append("csvFile", this.csvFile);
        }
        return data;
    }
}

export class PlansConfig {
    public constructor(public code: string, public filters: string) {}
}

export class PlansConfigRequest {
    public constructor(public plansConfig: PlansConfig[]) {}
}
