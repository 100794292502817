import React, { ChangeEvent, PureComponent } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { CompAccountEnum } from "../../config/permissions";
import { withPermission } from "../../hoc/withPermission";
import { CreateCompAccountPayload } from "../../services/PureFlixAdmin/Requests";

enum STRINGS {
    FIRST_NAME = "First name",
    LAST_NAME = "Last name",
    EMAIL = "Email",
    ACCOUNT_TYPE = "Account type",
    CREATE_ACCOUNT = "Create Account",
}

interface Product {
    id: string;
    description: string;
    productTags: {
        scheme: string;
        schemeLocalized?: any;
        title: string;
        titleLocalized?: any;
    }[];
}

interface Props {
    products: Product[];
    submitHandler: (payload: CreateCompAccountPayload) => Promise<any>;
    errorNotifier: (error: Error) => any;
    disabled: boolean;
}

interface State {
    firstName: string;
    lastName: string;
    email: string;
    productIndex: number;
}

const emptyProduct: Product = {
    id: "",
    description: "",
    productTags: [
        {
            scheme: "",
            title: "",
        },
    ],
};

interface ProductPayload {
    productId: string;
    productTags: string[];
}

const GuardCompAccountCreationButon = withPermission(Button, CompAccountEnum.CREATE);
class AddCompSubscriptionForm extends PureComponent<Props, State> {
    private static defaultSelectedProductIndex = 0;

    public constructor(props: Props) {
        super(props);
        this.state = AddCompSubscriptionForm.generateResetState();
    }

    private static generateResetState(): State {
        return {
            firstName: "",
            lastName: "",
            email: "",
            productIndex: AddCompSubscriptionForm.defaultSelectedProductIndex,
        };
    }

    private submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        const { firstName, lastName, email, productIndex } = this.state;
        const { products, submitHandler } = this.props;
        const { productId, productTags } = AddCompSubscriptionForm.makeProductPayload(products[productIndex]);

        event.preventDefault();

        try {
            const payload = { firstName, lastName, email, productId, productTags };
            await submitHandler(payload);
            this.setState(AddCompSubscriptionForm.generateResetState());
        } catch (err: unknown) {
            if (err instanceof Error) {
                // console.log(err.message);
            } else {
                // console.log("Unexpected error: ", err);
            }
        }
    };

    private static makeProductPayload = ({ id, productTags }: Product = emptyProduct): ProductPayload => {
        return {
            productId: id,
            productTags: productTags.map((tag) => tag.scheme),
        };
    };

    private handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ firstName: event.target.value });
    };

    private handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ lastName: event.target.value });
    };

    private handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ email: event.target.value });
    };

    private handleProductChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ productIndex: Number(event.target.value) });
    };

    private static generateProductOption({ id, description }: Product, index: number): JSX.Element {
        return (
            <option key={id} value={index}>
                {description}
            </option>
        );
    }

    public render(): JSX.Element {
        const { FIRST_NAME, LAST_NAME, EMAIL, ACCOUNT_TYPE, CREATE_ACCOUNT } = STRINGS;
        const { products, disabled = false } = this.props;
        const { firstName, lastName, email, productIndex } = this.state;

        return (
            <Form id="form-add-comp" onSubmit={this.submitForm} className="col-sm-3">
                <fieldset disabled={disabled}>
                    <FormGroup>
                        <Label htmlFor="firstname">{FIRST_NAME}</Label>
                        <Input type="text" name="firstname" id="firstname" onChange={this.handleFirstNameChange} required value={firstName} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="lastname">{LAST_NAME}</Label>
                        <Input type="text" name="lastname" id="lastname" onChange={this.handleLastNameChange} required value={lastName} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="email">{EMAIL}</Label>
                        <Input type="email" name="email" id="email" onChange={this.handleEmailChange} required value={email} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="product">{ACCOUNT_TYPE}</Label>
                        <Input type="select" name="product" id="product" onChange={this.handleProductChange} value={productIndex}>
                            {products.map(AddCompSubscriptionForm.generateProductOption)}
                        </Input>
                    </FormGroup>

                    {!disabled && <GuardCompAccountCreationButon color="primary">{CREATE_ACCOUNT}</GuardCompAccountCreationButon>}
                </fieldset>
            </Form>
        );
    }
}

export default AddCompSubscriptionForm;
