import React, { Component } from 'react';
import { RouteComponentProps } from "react-router";
import { withRouter } from 'react-router-dom';

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    content: string;
};

class Title extends Component<PropsType> {
    public render(): JSX.Element {
        return (
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">{this.props.content}</h1>
            </div>
        );
    }
}

export default withRouter(Title);
