import axios, {AxiosInstance} from "axios";
import {PaymentInstrument} from "../../components/CSRTool/CSRTool";
import Util from "../../helpers/Util";

export interface WatchlistItem {
    id: string;
    title: string;
    updated: number;
    d2c$duration: string;
    d2c$progress: string;
}

export interface WatchListBeaconItem {
    id: string;
    playDurationSeconds: number;
    playStartDate: string;
    deviceType: string;
    lastPlayPositionSeconds: number;
    logDate: string;
    media_entry: {
        title: string;
        durationSecs: number;
    };
}

class CustomerService {
    private endpoint = "/customers";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getProfile(findBy: string, inputValue: string): Promise<any> {
        try {
            const config = {
                params: {findBy, inputValue},
            };
            const res = await this.service.get(`${this.endpoint}/profiles`, config);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getWatchlist(userId: number): Promise<WatchlistItem[]> {
        try {
            const res = await this.service.get(`${this.endpoint}/${userId}/watchlist`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getWatchlistFromBeaconReport(userId: number): Promise<WatchListBeaconItem[]> {
        try {
            const res = await this.service.get(`${this.endpoint}/${userId}/watchlist/beacon-report`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getPaymentInstruments(username: number): Promise<PaymentInstrument[]> {
        try {
            const res = await this.service.get(`${this.endpoint}/${username}/payment-instruments`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async deletePaymentInstruments(username: number): Promise<boolean> {
        try {
            await this.service.delete(`${this.endpoint}/${username}/payment-instruments`);
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async updateUserProfile(username: number, payload: Record<string, string>): Promise<boolean> {
        try {
            await this.service.put(`${this.endpoint}/${username}/profiles`, {...payload});
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async deleteUserProfile(email: string): Promise<boolean> {
        try {
            await this.service.delete(`${this.endpoint}/profiles`, {data: {email}});
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new CustomerService();
