import { PureComponent } from "react";
import { Button, Table } from "reactstrap";
import Util from "../../helpers/Util";
import DetailsModal from "./DetailsModal";
import EpisodeModal from "./EpisodeModal";
import Thumbnail from "./Thumbnail";
import { Media, Program, Thumbnails } from "./Types";

interface Props {
    item: Program;
    restrictions: any[];
    getAllSeasons: (item: Program, seasonsNumber: number) => Promise<Program[][]>;
}

interface State {
    isEpisodeModalVisible: boolean;
    isDetailsModalVisible: boolean;
    isModalLoading: boolean;
    episode: Program | undefined;
    seasons: Program[][];
    isImgLoaded: boolean;
    availability: number;
}

type ProgramType = "movie" | "episode" | "series";

enum STRINGS {
    TITLE = "Title",
    DURATION = "Duration",
    DESCRIPTION = "Description",
    PROGRAM_TYPE = "Program Type",
    AVAILABLE_DATE = "First Episode Available Date",
    COUNTRIES = "Available countries",
    EXPIRATION_DATE = "Last Episode Expiration date",
    SERIES_TITLE = "Series title",
    AVAILABILITY = "Availability",
    EPISODE_AVAILABLE_DATE = "Available Date",
    EPISODE_EXPIRATION_DATE = "Expiration Date",
    RELEASE_YEAR = "Release Date",
    RESTRICTIONS = "Restrictions",
    CASTING = "Casting",
    KEYWORDS = "Keywords",
    RATINGS = "Ratings",
    DRM_PROTECTED = "DRM Protected",
}

const styling = {
    tableRowAvailable: {
        backgroundColor: "#309646",
        color: "#FFF",
        fontWeight: 600,
    },
    tableRowUnavailable: {
        backgroundColor: "#FF2836",
        color: "#fff",
        fontWeight: 600,
    },
    tableRowLoading: {
        backgroundColor: "#ffffff",
        color: "#000",
        fontWeight: 600,
    },
    tableRowNotAvailable: {
        backgroundColor: "#ffffff",
        color: "#000",
        fontWeight: 600,
    },
    tableRowComingSoon: {
        backgroundColor: "#F29718",
        color: "#000",
        fontWeight: 600,
    },
    tableRowWorkingOnTitle: {
        backgroundColor: "#6D6CFF",
        color: "#000",
        fontWeight: 600,
    },
};

class ProgramsTable extends PureComponent<Props, State> {
    private account: string;

    public imageType: { [key in ProgramType]: keyof Thumbnails[] } = {
        ["movie"]: ["Default-2160x3840"],
        ["series"]: ["Default-2160x3840"],
        ["episode"]: ["Default-1080x1920"],
    };
    public constructor(props: Props) {
        super(props);
        this.state = {
            isEpisodeModalVisible: false,
            isDetailsModalVisible: false,
            isModalLoading: false,
            episode: undefined,
            seasons: [],
            isImgLoaded: false,
            availability: 0,
        };
        this.account = Util.getOwnerId();
    }

    private calculateEpisodeAvailability = (item: Media): { text: string; style: React.CSSProperties } => {
        const workInProgress = item?.content?.filter((item) => item.format === "M3U") ?? [];

        if (item?.availabilityState === "available" && item?.availableDate < new Date() && item?.expirationDate > new Date()) {
            return { text: "Title Available", style: styling.tableRowAvailable };
        } else if (workInProgress.length > 1 && item?.availabilityState === "available") {
            return { text: "Working on Title", style: styling.tableRowWorkingOnTitle };
        } else if (item?.availabilityState === "expired") {
            return { text: "Title Not Available", style: styling.tableRowUnavailable };
        } else if ((item?.availabilityState === "notYetAvailable" || item?.availabilityState === "planned") && item?.availableDate > new Date()) {
            return { text: "Coming Soon", style: styling.tableRowComingSoon };
        } else {
            return { text: "Unknown", style: styling.tableRowLoading };
        }
    };

    private showSpinner(): void {
        this.setState({ isModalLoading: true });
    }

    private hideSpinner(): void {
        this.setState({ isModalLoading: false });
    }

    private showEpisodeModal = (show: boolean): void => {
        this.setState({
            isEpisodeModalVisible: show,
        });
    };
    private showDetailsModal = (show: boolean): void => {
        this.setState({
            isDetailsModalVisible: show,
        });
    };

    private makeRow(key: string, value: string | number, element?: JSX.Element): JSX.Element {
        return (
            <tr key={key}>
                <th>{key ? <div>{key}:</div> : element}</th>
                <td>{value}</td>
            </tr>
        );
    }
    private makeRowWithLabel(key: string, value: string | number, element?: JSX.Element | null, style?: React.CSSProperties): JSX.Element {
        return (
            <tr key={key}>
                <th>{key ? <div>{key}:</div> : element}</th>
                <td style={style}>
                    <div>{value}</div>
                </td>
            </tr>
        );
    }

    private setLoad = (value: boolean) => {
        this.setState({ isImgLoaded: value });
    };

    private getsEarliestMediasDate = (media: Media[]): number => {
        const dates = media.map((item) => item.availableDate);
        return Math.min(...dates);
    };

    private getsLatestMediasDate = (media: Media[]): number => {
        const dates = media.map((item) => item.expirationDate);
        return Math.max(...dates);
    };

    private moreDetails = () => (
        <Button color="primary" onClick={this.handleDetailsModal} style={{ width: "100%", margin: 4 }}>
            Show More Info
        </Button>
    );
    private episodesButton = () => (
        <Button color="primary" onClick={this.handleModal} style={{ width: "100%", margin: 4 }}>
            Episodes
        </Button>
    );

    private generateProgramResultsRows = (item: Program): JSX.Element[] => {
        const { DESCRIPTION, PROGRAM_TYPE, AVAILABILITY, TITLE } = STRINGS;

        const { description, programType, media, seriesTitle, thumbnails } = item;
        const numRows = 5;
        const usedRows = seriesTitle ? numRows + 1 : numRows;
        const programsThumbnailRow = (
            <tr key="thumb">
                <th style={{ width: 150 }} rowSpan={usedRows}>
                    <Thumbnail thumbnails={thumbnails} setLoad={this.setLoad} imageType={this.imageType[programType as ProgramType]} />
                    <br />
                    {programType === "series" ? this.episodesButton() : null}
                    {this.moreDetails()}
                </th>
            </tr>
        );

        const [mediaSource] = media;
        const availability = this.calculateEpisodeAvailability(mediaSource).text;

        return [
            programsThumbnailRow,
            this.makeRow(TITLE, item.title),
            this.makeRow(PROGRAM_TYPE, programType.toUpperCase()),
            this.makeRow(DESCRIPTION, description),
            this.makeRowWithLabel(AVAILABILITY, availability, null, this.calculateEpisodeAvailability(mediaSource).style),
        ];
    };

    private generateDetailsResultsRows = (item: Program): JSX.Element[] => {
        const { EPISODE_AVAILABLE_DATE, DURATION, COUNTRIES, EPISODE_EXPIRATION_DATE, SERIES_TITLE, RELEASE_YEAR, CASTING, RATINGS, DRM_PROTECTED } = STRINGS;

        const { programType, media, seriesTitle, credits, ratings, pureflix$isDrmProtected, thumbnails, year } = item;

        const numRows = 9;
        const usedRows = item.seriesTitle ? numRows + 1 : numRows;
        let availableCountries = "";
        let availableDate = "";
        let expirationDate = "";
        let casting = "";
        let rating = "";
        const isDrmProtected = pureflix$isDrmProtected ? "Yes" : "No";
        let duration = "N/A";

        const mediaThumbnailRow = (
            <tr key={item.id}>
                <th rowSpan={usedRows} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", minWidth: 150 }}>
                    <Thumbnail
                        style={{ objectFit: "contain", width: "100%", height: "100%" }}
                        thumbnails={thumbnails}
                        setLoad={this.setLoad}
                        imageType={this.imageType[programType as ProgramType]}
                    />
                    <br />
                </th>
            </tr>
        );
        const seriesTitleRow = seriesTitle ? this.makeRow(SERIES_TITLE, seriesTitle) : <></>;
        const [mediaSource] = media;
        availableCountries = mediaSource.availabilityTags.length ? mediaSource.availabilityTags.join(", ") : "Unknown";

        availableDate = mediaSource.availableDate !== 0 ? Util.timestampToUTCString(mediaSource.availableDate) : "Unavailable";

        expirationDate = mediaSource.expirationDate !== 0 ? Util.timestampToUTCString(mediaSource.expirationDate) : "Unavailable";
        duration = Util.secondsToHHMMSS(mediaSource?.content?.[0].duration);
        casting = credits.map((item: any) => item.personName).join(", ");
        rating = ratings?.map((item) => item.rating.toUpperCase()).join(", ") ?? "";

        return [
            mediaThumbnailRow,
            seriesTitleRow,
            this.makeRow(EPISODE_AVAILABLE_DATE, availableDate),
            this.makeRow(EPISODE_EXPIRATION_DATE, expirationDate),
            this.makeRow(COUNTRIES, availableCountries),
            this.makeRow(DURATION, duration),
            this.makeRow(DRM_PROTECTED, isDrmProtected),
            this.makeRow(RELEASE_YEAR, year),
            this.makeRow(CASTING, casting),
            // this.makeRow(KEYWORDS, pureflix$keywords),
            this.makeRow(RATINGS, rating),
        ];
    };
    private generateMediaResultsRows = (item: Program): JSX.Element[] => {
        const { DESCRIPTION, PROGRAM_TYPE, EPISODE_AVAILABLE_DATE, COUNTRIES, EPISODE_EXPIRATION_DATE, TITLE, SERIES_TITLE, AVAILABILITY } = STRINGS;

        const { description, programType, media, seriesTitle, title, thumbnails } = item;

        const numRows = 8;
        const usedRows = item.seriesTitle ? numRows + 1 : numRows;
        let availableCountries = "";
        let availableDate = "";
        let expirationDate = "";
        let availability = "";

        const mediaThumbnailRow = (
            <tr key="thumb">
                <th rowSpan={usedRows} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", minWidth: 150 }}>
                    <Thumbnail
                        style={{ objectFit: "contain", width: "100%", height: "100%" }}
                        thumbnails={thumbnails}
                        setLoad={this.setLoad}
                        imageType={this.imageType[programType as ProgramType]}
                    />
                    <br />
                </th>
            </tr>
        );
        const titleRow = title ? this.makeRow(TITLE, title) : <></>;
        const seriesTitleRow = seriesTitle ? this.makeRow(SERIES_TITLE, seriesTitle) : <></>;

        const [mediaSource] = media;
        availability = this.calculateEpisodeAvailability(mediaSource).text;
        availableCountries = mediaSource.availabilityTags.length ? mediaSource.availabilityTags.join(", ") : "Unknown";
        availableDate = mediaSource.availableDate !== 0 ? Util.timestampToUTCString(mediaSource.availableDate) : "Unavailable";
        expirationDate = mediaSource.expirationDate !== 0 ? Util.timestampToUTCString(mediaSource.expirationDate) : "Unavailable";

        return [
            mediaThumbnailRow,
            seriesTitleRow,
            titleRow,
            this.makeRow(PROGRAM_TYPE, programType.toUpperCase()),
            this.makeRow(EPISODE_AVAILABLE_DATE, availableDate),
            this.makeRow(COUNTRIES, availableCountries),
            this.makeRow(EPISODE_EXPIRATION_DATE, expirationDate),
            this.makeRow(DESCRIPTION, description),
            this.makeRowWithLabel(AVAILABILITY, availability, null, this.calculateEpisodeAvailability(mediaSource).style),
        ];
    };

    private generateProgramResultsTable = (item: Program): JSX.Element => (
        <>
            <div className="title">
                <h3>{item.title}</h3>
            </div>
            <Table hover={true} bordered={true} className="contracts-rows">
                <tbody>{this.generateProgramResultsRows(item)}</tbody>
            </Table>
        </>
    );
    private generateEpisodeResultsTable = (item: Program): JSX.Element => {
        const [thumbnail, ...rest] = this.generateMediaResultsRows(item);
        return (
            <>
                <div className="title">
                    <h3>{item.title}</h3>
                </div>
                <div style={{ border: "solid #f2f2f2 2px", justifyContent: "center", alignItems: "center" }}>
                    {thumbnail}
                    <Table hover={true} bordered={true} className="contracts-rows">
                        <tbody>{rest}</tbody>
                    </Table>
                </div>
            </>
        );
    };
    private generateDetailsResultsTable = (item: Program): JSX.Element => {
        const [thumbnail, ...rest] = this.generateDetailsResultsRows(item);
        return (
            <div key={item.id}>
                <div className="title">
                    <h3>{item.title}</h3>
                </div>
                <div style={{ border: "solid #f2f2f2 2px", justifyContent: "center", alignItems: "center" }}>
                    {thumbnail}
                    <Table hover={true} bordered={true} className="contracts-rows">
                        <tbody>{rest}</tbody>
                    </Table>
                </div>
            </div>
        );
    };

    private toggleModal = () => {
        this.showEpisodeModal(!this.state.isEpisodeModalVisible);
    };
    private toggleDetailsModal = () => {
        this.showDetailsModal(!this.state.isDetailsModalVisible);
    };

    private handleDetailsModal = () => {
        this.toggleDetailsModal();
        // this.getDetails();
    };

    private handleModal = () => {
        this.getSeasons();
        this.toggleModal();
    };

    private getSeasons = async (): Promise<void> => {
        const { item, getAllSeasons } = this.props;
        this.showSpinner();
        const seasonsNumber = item.seriesTvSeasons.length;
        const seasons = await getAllSeasons(item, seasonsNumber);
        this.setState({ seasons });
        this.hideSpinner();
    };

    private filteredOwner = (item: Program): Program => {
        const filteredMedia = item.media.filter((el) => el.ownerId === this.account);
        item.media = filteredMedia;

        return item;
    };
    private generateProgramResults = (item: Program): JSX.Element | void => {
        return (
            <>
                {this.generateProgramResultsTable(item)}
                <br></br>
                <hr />
            </>
        );
    };

    public render(): JSX.Element | null {
        const { item } = this.props;
        const { isEpisodeModalVisible, isDetailsModalVisible, seasons } = this.state;
        const filteredItem = this.filteredOwner(item);

        return (
            <>
                {this.generateProgramResults(filteredItem)}
                {item.programType === "series" && (
                    <EpisodeModal
                        showEpisodeModal={isEpisodeModalVisible}
                        setModalVisibility={this.showEpisodeModal}
                        item={filteredItem}
                        seasons={seasons}
                        generateEpisodeTable={this.generateEpisodeResultsTable}
                        isModalLoading={this.state.isModalLoading}
                    />
                )}
                <DetailsModal
                    showDetailsModal={isDetailsModalVisible}
                    setModalVisibility={this.showDetailsModal}
                    item={filteredItem}
                    seasons={seasons}
                    generateDetailsTable={this.generateDetailsResultsTable}
                    isModalLoading={this.state.isModalLoading}
                />
            </>
        );
    }
}

export default ProgramsTable;
