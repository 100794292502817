export enum RoleEnum {
    MSR_STANDARD = "MSR_STANDARD",
    MSR_ADVANCED = "MSR_ADVANCED",
    MSR_SUPERVISOR = "MSR_SUPERVISOR",
    ADMIN_READ = "ADMIN_READ",
    ADMIN_FULL = "ADMIN_FULL",
    UNDEFINED = "UNDEFINED",
}
// Below is the function to format the role to a user friendly name.
export const formatRoleName = (role: RoleEnum): string => {
    const formattedRole = {
        [RoleEnum.ADMIN_FULL]: "Admin",
        [RoleEnum.ADMIN_READ]: "Admin (Read-Only)",
        [RoleEnum.MSR_ADVANCED]: "Advanced",
        [RoleEnum.MSR_STANDARD]: "Standard",
        [RoleEnum.MSR_SUPERVISOR]: "Supervisor",
        [RoleEnum.UNDEFINED]: "Undefined",
    }[role];
    return formattedRole;
};

export enum TaskRunnerEnum {
    VIEW = "view_task_runner",
    RUN = "run_task_runner",
}

export enum PlansEnum {
    CONFIGURE_PLAN = "add_and_edit_plans",
    VIEW_PLAN = "view_plans",
    DELETE_PLAN = "delete_plans",
    ADD_PAGE = "add_page",
}

export enum AccountCleanUpEnum {
    VIEW = "view_account_cleanup",
    RUN = "run_account_cleanup",
}
export enum CompAccountEnum {
    VIEW = "view_comp_account",
    CREATE = "create_comp_account",
}

export enum MembershipAccountInformationEnum {
    VIEW = "view_account_membership_info",
    SWITCH = "switch_account_membership_info",
    CANCEL = "cancel_account_membership_info",
}

export enum MembershipAccountUpdateEnum {
    VIEW = "view_account_update",
    CHANGE_EMAIL = "change_email_account_update",
    CHANGE_NAME = "change_name_account_update",
    DELETE = "delete_account_update",
    PASSWORD = "password_reset_account_update",
    LOCK = "lock_account_update",
    OPT_OUT = "opt_out_account_update",
}

export enum BillingHistoryEnum {
    VIEW = "view_billing_history",
    SKIP = "skip_billing_history",
    DISCOUNT = "discount_billing_history",
    REFUND = "refund_billing_history",
}
export enum ViewHistoryEnum {
    VIEW = "view_viewing_history",
}

export enum GiftVoucherEnum {
    VIEW = "view_gift_voucher",
}
export enum ProgramsEnum {
    VIEW = "view_programs",
}

export enum AvailableRoutesEnum {
    TASK_RUNNER = "/task-runner",
    COMP_ACCOUNTS = "/comp-accounts",
    CSR_TOOL = "/csr-tool",
    ACCOUNT_CLEANUP = "/account-cleanup",
    PLANS = "/plans",
    GIFTS = "/gift-vouchers",
    USER_MANAGEMENT = "/user-management",
    PROGRAMS = "/programs",
    LOGS = "/system-logs",
    FORBIDDEN = "/forbbiden",
}

// Bove are the enum to faciliate the string management.
// Below are the types definitions for the application.

export type Permission =
    | TaskRunnerEnum
    | PlansEnum
    | AccountCleanUpEnum
    | CompAccountEnum
    | MembershipAccountInformationEnum
    | MembershipAccountUpdateEnum
    | BillingHistoryEnum
    | ViewHistoryEnum
    | GiftVoucherEnum
    | ProgramsEnum
    | AvailableRoutesEnum;

export type RolePermissions = Permission[];

// Above are the types used in the application.

// Below are the values used in the application.
const msrStandard: RolePermissions = [
    MembershipAccountInformationEnum.VIEW,
    MembershipAccountInformationEnum.SWITCH,
    MembershipAccountInformationEnum.CANCEL,
    MembershipAccountUpdateEnum.VIEW,
    MembershipAccountUpdateEnum.LOCK,
    MembershipAccountUpdateEnum.PASSWORD,
    BillingHistoryEnum.VIEW,
    BillingHistoryEnum.SKIP,
    BillingHistoryEnum.DISCOUNT,
    BillingHistoryEnum.REFUND,
    ViewHistoryEnum.VIEW,
    GiftVoucherEnum.VIEW,
    ProgramsEnum.VIEW,
    AvailableRoutesEnum.CSR_TOOL,
    AvailableRoutesEnum.GIFTS,
    AvailableRoutesEnum.PROGRAMS,
];

const msrAdvanced: RolePermissions = [
    ...msrStandard,
    MembershipAccountUpdateEnum.CHANGE_EMAIL,
    MembershipAccountUpdateEnum.CHANGE_NAME,
    MembershipAccountUpdateEnum.DELETE,
    MembershipAccountUpdateEnum.OPT_OUT,
];

const msrSuperviser: RolePermissions = [
    ...msrAdvanced,
    AccountCleanUpEnum.RUN,
    AccountCleanUpEnum.VIEW,
    CompAccountEnum.VIEW,
    CompAccountEnum.CREATE,
    AvailableRoutesEnum.ACCOUNT_CLEANUP,
    AvailableRoutesEnum.COMP_ACCOUNTS,
];

const noRole: RolePermissions = [];

export const roles = [RoleEnum.ADMIN_FULL, RoleEnum.ADMIN_READ, RoleEnum.MSR_SUPERVISOR, RoleEnum.MSR_ADVANCED, RoleEnum.MSR_STANDARD];

export const accessLevels = roles.map((item) => ({
    name: item,
    label: item,
}));

// Add all features below here after creating the corresponding enum type above!
export const permissionsTree: { [feature: string]: Permission[] } = {
    ["Task Runner"]: Object.values(TaskRunnerEnum),
    ["Plans"]: Object.values(PlansEnum),
    ["Account CleanUp"]: Object.values(AccountCleanUpEnum),
    ["Comp Account"]: Object.values(CompAccountEnum),
    ["Membership Account Information"]: Object.values(MembershipAccountInformationEnum),
    ["Membership Account Update"]: Object.values(MembershipAccountUpdateEnum),
    ["Billing History"]: Object.values(BillingHistoryEnum),
    ["View History"]: Object.values(ViewHistoryEnum),
    ["Gift Voucher"]: Object.values(GiftVoucherEnum),
    ["Programs"]: Object.values(ProgramsEnum),
    ["Available Routes"]: Object.values(AvailableRoutesEnum),
};

const adminFull: RolePermissions = Object.values(permissionsTree)
    .map((permission) => permission)
    .flat();

const adminRead: RolePermissions = Object.values(permissionsTree)
    .map((permission) => permission)
    .flat();

// main final object that maps roles to its permissions!
export const permissions: { [x in keyof typeof RoleEnum]: RolePermissions } = {
    [RoleEnum.MSR_STANDARD]: msrStandard,
    [RoleEnum.MSR_ADVANCED]: msrAdvanced,
    [RoleEnum.MSR_SUPERVISOR]: msrSuperviser,
    [RoleEnum.ADMIN_FULL]: adminFull,
    [RoleEnum.ADMIN_READ]: adminRead,
    [RoleEnum.UNDEFINED]: noRole,
};
