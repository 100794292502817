import React, { PureComponent } from "react";
import { Table } from "reactstrap";
import { WatchlistItem } from '../../services/PureFlixAdmin/CustomerService';
import Util from "../../helpers/Util";

interface Props {
    watchlist: WatchlistItem[];
}

enum STRINGS {
    TITLE = "Title",
    LAST_BEACON = "Last beacon",
    DURATION = "Duration",
    PROGRESS = "Progress"
}

class CustomerWatchlistTable extends PureComponent<Props> {

    private makeHeader(): JSX.Element {
        const { TITLE, LAST_BEACON, DURATION, PROGRESS } = STRINGS;

        return (
            <thead>
                <tr>
                    <th>{TITLE}</th>
                    <th>{LAST_BEACON}</th>
                    <th>{DURATION}</th>
                    <th>{PROGRESS}</th>
                </tr>
            </thead>
        );
    }

    private makeRow(watchlistItem: WatchlistItem, index: number): JSX.Element {
        const { title, updated, d2c$duration, d2c$progress } = watchlistItem;

        return (
            <tr key={index}>
                <td>{title}</td>
                <td>{Util.timestampToUTCString(updated)}</td>
                <td>{Util.secondsToHHMMSS(d2c$duration)}</td>
                <td>{Util.secondsToHHMMSS(d2c$progress)}</td>
            </tr>
        );
    }

    public render(): JSX.Element {
        const { watchlist = [] } = this.props;

        if (!watchlist.length) {
            return <div>No data to show</div>;
        }

        return (
            <Table hover={true} bordered={true}>
                {this.makeHeader()}
                <tbody>{watchlist.map(this.makeRow)}</tbody>
            </Table>
        );
    }
}

export default CustomerWatchlistTable;
