import React, { PureComponent } from "react";
import { Table } from "reactstrap";
import { WatchListBeaconItem } from '../../services/PureFlixAdmin/CustomerService';
import Util from "../../helpers/Util";

interface Props {
    watchlist: WatchListBeaconItem[];
}

class CustomerWatchlistBeaconTable extends PureComponent<Props> {

    private makeHeader(): JSX.Element {
        return (
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Last beacon</th>
                    <th>Device</th>
                    <th>Duration</th>
                    <th>Play Duration</th>
                    <th>Last play position</th>
                </tr>
            </thead>
        );
    }

    /* eslint-disable */
    private makeRow(watchlistItem: WatchListBeaconItem): JSX.Element {
        const { id, media_entry, logDate, deviceType, playDurationSeconds, lastPlayPositionSeconds } = watchlistItem;
        const playDuration = Util.secondsToHHMMSS((playDurationSeconds > media_entry.durationSecs) ?
            media_entry.durationSecs : playDurationSeconds);

        return (
            <tr key={id}>
                <td>{media_entry.title}</td>
                <td>{new Date(logDate).toUTCString()}</td>
                <td>{deviceType}</td>
                <td>{Util.secondsToHHMMSS(media_entry.durationSecs)}</td>
                <td>{playDuration}</td>
                <td>{Util.secondsToHHMMSS(lastPlayPositionSeconds)}</td>
            </tr>
        );
    }
    /* eslint-enable */

    public render(): JSX.Element {
        const { watchlist = [] } = this.props;

        if (!watchlist.length) {
            return <div>No data to show</div>;
        }

        return (
            <Table hover={true} bordered={true}>
                {this.makeHeader()}
                <tbody>{watchlist.map(this.makeRow)}</tbody>
            </Table>
        );
    }
}

export default CustomerWatchlistBeaconTable;
