import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';
import { Buttons, PlansConfig } from '../../services/PureFlixAdmin/PlansService';
import PlansConfigButtonForm from './PlansConfigButtonForm';
import Util from "../../helpers/Util";
import { withPermission } from '../../hoc/withPermission';
import { PlansEnum } from '../../config/permissions';

interface PropsType {
    configList: PlansConfig[];
    showModalForm: boolean;
    toggleModalAddConfig: () => void;
    saveConfig: (config: PlansConfig) => void;
}
interface State {
    disableForm: boolean;
    msg: string;
    pageCode: string;
    buttons: {
        buttonCode: string;
        filters: string;
    }[];
}

const GuardAddButton = withPermission(Button, PlansEnum.CONFIGURE_PLAN);
class PlansAddConfigForm extends Component<PropsType> {

    public state: State = {
        msg: '',
        disableForm: false,
        pageCode: '',
        buttons: [{
            buttonCode: '',
            filters: ''
        }]
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>, indexButton: number): void => {
        event.persist();
        const target = event.target;

        if (target.name === 'pageCode') {
            this.setState({ pageCode: target.value });
        }
        else if (target.name === 'buttonCode') {
            this.setState((state: State) => {
                return state.buttons[indexButton].buttonCode = target.value;
            });
        }
        else {
            this.setState((state: State) => {
                return state.buttons[indexButton].filters = target.value;
            });
        }
    }

    private toggleModalItem = (): void => {
        this.setState({
            pageCode: '',
            buttons: [{
                buttonCode: '',
                filters: ''
            }],
            msg: ''
        });
        this.props.toggleModalAddConfig();
    }

    private checkDuplicatesInConfigButtons(buttons: Buttons[]): void {
        const seen = new Set();
        buttons.some(function (currentObject) {
            if (seen.size === seen.add(currentObject.buttonCode).size) {
                throw new Error(`There is a duplicate button`);
            }
            if (seen.size === seen.add(currentObject.filters).size) {
                throw new Error(`There is a duplicate filter`);
            }
        });
    }

    private saveConfig = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            event.preventDefault();
            this.setState({ disableForm: true, msg: '' });

            const pageCodes = this.props.configList.map(config => config.pageCode);
            Util.checkDuplicates(pageCodes, this.state.pageCode);

            this.checkDuplicatesInConfigButtons(this.state.buttons);

            const buttons = this.state.buttons.map(button => {
                const buttonCode = Util.validateInput(button.buttonCode);
                const filters = Util.validateInput(button.filters);
                return { buttonCode, filters };
            });

            const plan = {
                pageCode: Util.validateInput(this.state.pageCode),
                buttons: buttons
            };

            await this.props.saveConfig(plan);

            this.setState({
                disableForm: false,
                buttons: [{
                    buttonCode: '',
                    filters: ''
                }],
                pageCode: ''
            });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            }
            else {
                this.setState({ msg: "Unknown error in PlansAddConfigForm.tsx:saveConfig", disableForm: false });
            }
        }
    }

    private addConfigFormButton = (): void => {
        this.setState({
            msg: '',
            buttons: [...this.state.buttons, { buttonCode: '', filters: '' }]
        });
    }

    private removeConfigFormButton = (indexButton: number): void => {
        const buttons = [...this.state.buttons];

        buttons.splice(indexButton, 1);
        this.setState({
            buttons,
            msg: ''
        });
    }

    public render(): JSX.Element {
        return (
            <>
                <Modal isOpen={this.props.showModalForm} toggle={this.toggleModalItem}>
                    <ModalHeader toggle={this.toggleModalItem}>
                        Add Page Config</ModalHeader>
                    <Form id="category-edit-form" className="cubo-form" onSubmit={this.saveConfig}>
                        <fieldset disabled={this.state.disableForm}>
                            <ModalBody>
                                <div className="form-group row">
                                    <Label htmlFor="codeField" className="col-sm-2 col-form-label">Page Code</Label>
                                    <div className="col-sm-10">
                                        <Input type="text" name="pageCode" className="form-control"
                                            id="codeField" aria-describedby="codeHelp" required
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.handleChange(event, 0)}
                                            value={this.state.pageCode}
                                        />
                                    </div>
                                </div>
                                {this.state.buttons.map((button, index): JSX.Element => {
                                    return (
                                        <PlansConfigButtonForm
                                            key={index}
                                            indexButton={index}
                                            buttonCode={button.buttonCode}
                                            filters={button.filters}
                                            buttons={this.state.buttons}
                                            handleChange={this.handleChange}
                                            removeConfigFormButton={this.removeConfigFormButton}
                                        />
                                    );
                                })}
                                <GuardAddButton
                                    color='primary'
                                    className='add-config btn-dark'
                                    onClick={(): void => this.addConfigFormButton()}
                                >Add Plan
                                </GuardAddButton>

                            </ModalBody>
                            <ModalFooter>
                                <span className={this.state.msg ? 'alert alert-warning' : ''} role="alert">{this.state.msg}</span>
                                <Button type='submit' className="btn btn-dark" color="secondary">Save</Button>
                            </ModalFooter>
                        </fieldset>
                    </Form>
                </Modal>

            </>
        );
    }
}

export default PlansAddConfigForm;
