import axios, { AxiosInstance } from 'axios';
import Util from "../../helpers/Util";
import { CreateCompAccountPayload } from "./Requests";
import { CompProduct } from "./Responses";

class CompAccountsService {
    private endpoint = "/comp-accounts";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async createAccount(payload: CreateCompAccountPayload): Promise<boolean> {
        try {
            await this.service.post(this.endpoint, payload);
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }

    }

    public async getAvailableProducts(): Promise<CompProduct[]> {
        const { data = [] } = await this.service.get(`${this.endpoint}/products`);
        return data;
    }
}

export default new CompAccountsService();
