import axios, { AxiosInstance } from "axios";
import Util from "../../helpers/Util";

class ProgramService {

    private restrictionsEndpoint = "/restrictions";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getRestrictions(): Promise<{numberOfPages:number, restrictions:string[]}> {
        try {

            const { data } = await this.service.get(this.restrictionsEndpoint);

            return data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new ProgramService();
