import { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import AuthHelper from "../helpers/AuthHelper";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

interface State {
    content: string;
}

class Logout extends PureComponent<PropsType, State> {
    public constructor(props: PropsType) {
        super(props);
        AuthHelper.logout();
        this.props.history.push("/");
    }

    public render(): null {
        return null;
    }
}

export default withRouter(Logout);
