import React from "react";
import { withRouter } from "react-router";
import { Button, Container, Form, FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Spinner, Table } from "reactstrap";
import { AvailableRoutesEnum } from "../../config/permissions";
import { withPermission } from "../../hoc/withPermission";
import SystemLogsService, { SystemLog } from "../../services/PureFlixAdmin/SystemLogsService";
import Title from "../Title";

interface State {
    logs: SystemLog[];
    username: string;
    logsDate: Date;
    currentPage: number;
    pageCount: number;
    isLoading: boolean;
}

type Props = Record<string, unknown>;
const PAGE_SIZE = 100;

class SystemLogs extends React.PureComponent<Props, State> {
    private _isMounted = false;
    public state = {
        logs: [] as SystemLog[],
        username: "",
        logsDate: new Date(),
        currentPage: 1,
        pageCount: 0,
        isLoading: true,
    };

    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.loadLogs();
        }
    }
    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private loadLogs = () => {
        const { currentPage, username, logsDate } = this.state;
        this.setState({ isLoading: true });
        SystemLogsService.getLogs({ username: username, date: new Date(logsDate), page: currentPage }).then((logsResponse) => {
            if (this._isMounted) {
                this.setState({
                    isLoading: false,
                    logs: logsResponse.rows,
                    pageCount: Math.ceil(logsResponse.count / PAGE_SIZE),
                    currentPage: 1,
                });
            }
        });
    };

    private goToPage(page: number) {
        this.setState({ currentPage: page }, () => {
            this.loadLogs();
        });
    }

    private onChangeForm = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [evt.target.id]: evt.target.value,
        } as any);
    };

    private setLogDates = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.valueAsDate) {
            this.setState({ logsDate: evt.target.valueAsDate as Date });
        }
    };

    private LogsTable = (): JSX.Element => {
        const { logs } = this.state;
        const pagination = this.TablePagination();
        return (
            <>
                {pagination}
                <Table>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>User</th>
                            <th>Resource</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log, key) => (
                            <tr key={key}>
                                <td>{log.action}</td>
                                <td>{log.username}</td>
                                <td>{log.resource}</td>
                                <td>{new Date(log.logDate).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {pagination}
            </>
        );
    };

    private SearchForm = (): JSX.Element => {
        const { username, logsDate } = this.state;
        const dateStr = logsDate.toISOString().split("T")[0];
        return (
            <Form inline style={{ marginBottom: "1em" }}>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="username" className="mr-sm-2">
                        Username (email):
                    </Label>
                    <Input type="email" id="username" value={username} onChange={this.onChangeForm} />
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="username" className="mr-sm-2">
                        Date:
                    </Label>
                    <Input type="date" value={dateStr} onChange={this.setLogDates} />
                </FormGroup>
                <Button type="button" color="primary" onClick={this.loadLogs}>
                    Filter
                </Button>
            </Form>
        );
    };

    private TablePagination = (): JSX.Element => {
        const { currentPage, pageCount } = this.state;
        const disabled = pageCount <= 1;
        const pages: JSX.Element[] = [];

        for (let x = 1; x <= pageCount; x++) {
            pages.push(
                <PaginationItem key={x} disabled={disabled || currentPage === x}>
                    <PaginationLink href="#" onClick={() => this.goToPage(x)}>
                        {x}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return (
            <Pagination>
                <PaginationItem disabled={disabled || currentPage === 1}>
                    <PaginationLink first href="#" onClick={() => this.goToPage(1)} />
                </PaginationItem>
                <PaginationItem disabled={disabled || currentPage === 1}>
                    <PaginationLink previous href="#" />
                </PaginationItem>
                {pages}
                <PaginationItem disabled={disabled || currentPage === pageCount}>
                    <PaginationLink next href="#" />
                </PaginationItem>
                <PaginationItem disabled={disabled || currentPage === pageCount}>
                    <PaginationLink last href="#" />
                </PaginationItem>
            </Pagination>
        );
    };

    public render(): JSX.Element {
        const { isLoading } = this.state;
        return (
            <Container fluid={true}>
                <Title content={"Action Logs"} />
                <this.SearchForm />
                {isLoading ? <Spinner color="primary" /> : <this.LogsTable />}
            </Container>
        );
    }
}

export default withRouter(withPermission(SystemLogs, AvailableRoutesEnum.LOGS));
