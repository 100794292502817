import React, { ChangeEvent, PureComponent } from "react";
import ProgressBar from "react-progress-bar-plus";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, Spinner } from "reactstrap";
import AuthService from "../../services/PureFlixAdmin/AuthService";
import { Credentials } from "../../services/PureFlixAdmin/Requests";

import "../../styles/login.scss";

// Type whatever you expect in 'this.props.match.params.*'
interface PathParamsType {
    history: any;
}

// Your component own properties
type PropsType = RouteComponentProps<PathParamsType> & {
    history: {
        location: {
            state: {
                message: string;
            };
        };
    };
};

interface State extends Credentials {
    msg: string | string[] | null | undefined;
    loadProgress: number;
    isLoading: boolean;
    disableForm: boolean;
}

enum STRINGS {
    TITLE = "Pure Flix Admin Portal",
    SIGN_IN = "Sign In",
    USERNAME_PLACEHOLDER = "username",
    PASSWORD_PLACEHOLDER = "Password",
}

class Login extends PureComponent<PropsType, State> {
    private static usernamePrefix = "mpx/";
    private static usernamePlaceholder = STRINGS.USERNAME_PLACEHOLDER;
    private static passwordPlaceholder = STRINGS.PASSWORD_PLACEHOLDER;
    private static onLoginSuccessfulPath = "/task-runner";

    public constructor(props: PropsType) {
        super(props);

        let msg = "";
        if (this.props.history.location.state) {
            msg = this.props.history.location.state.message;
        }

        this.state = Object.assign(
            {
                msg,
                loadProgress: 0,
                isLoading: false,
                disableForm: false,
            },
            Credentials.getEmptyInstance()
        );
    }

    private loaderToZero(): void {
        this.setState({ loadProgress: 0, isLoading: false, disableForm: false });
    }

    private startLoader(): void {
        this.setState({
            msg: "",
            loadProgress: 10,
            isLoading: true,
            disableForm: true,
        });
    }

    private endLoader(): void {
        this.setState({
            loadProgress: 100,
            isLoading: false,
            disableForm: false,
        });
    }

    private setError(err: any): void {
        let msg = err.message;
        // console.log(err.message);
        if (err.response) {
            msg = err.response.data.message;
        }
        this.loaderToZero();
        this.setState({ msg });
    }

    private send = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        try {
            const { username, password } = this.state;
            const credentials = new Credentials(`${Login.usernamePrefix}${username}`, password);

            this.startLoader();
            await AuthService.login(credentials);
            this.endLoader();

            window.location.href = Login.onLoginSuccessfulPath;
        } catch (err) {
            this.setError(err);
        }
    };

    private handleUsernameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ username: event.target.value });
    };

    private handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ password: event.target.value });
    };

    private onUsernameInputBlur = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        if (value.startsWith(Login.usernamePrefix)) {
            const valueWithoutUsernamePrefix = value.replace(Login.usernamePrefix, "");
            this.setState({ username: valueWithoutUsernamePrefix });
        }
    };

    private renderForm(): JSX.Element {
        const isDisabled = this.state.disableForm;

        return (
            <Form onSubmit={this.send}>
                <FormGroup>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">{Login.usernamePrefix}</InputGroupAddon>
                        <Input
                            required
                            type="text"
                            name="username"
                            placeholder={Login.usernamePlaceholder}
                            onChange={this.handleUsernameChange}
                            onBlur={this.onUsernameInputBlur}
                            disabled={isDisabled}
                            value={this.state.username}
                            autoFocus
                        />
                    </InputGroup>
                </FormGroup>

                <FormGroup>
                    <Input required type="password" name="password" placeholder={Login.passwordPlaceholder} onChange={this.handlePasswordChange} disabled={isDisabled} />
                </FormGroup>

                <Button className="form-control" color="primary" disabled={isDisabled}>
                    {STRINGS.SIGN_IN}
                </Button>
            </Form>
        );
    }

    public render(): JSX.Element {
        return (
            <main>
                <ProgressBar percent={this.state.loadProgress} spinner={false} />
                <div className="login-box card form-group">
                    <h1 className="header-logo">{STRINGS.TITLE}</h1>
                    {this.renderForm()}
                </div>
                <div className="login-alert">
                    {this.state.isLoading && <Spinner color="primary" />}
                    <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                        {this.state.msg}
                    </span>
                </div>
            </main>
        );
    }
}

export default withRouter(Login);
