import axios, { AxiosInstance } from "axios";
import Util from "../../helpers/Util";

export interface Promotion {
    id: string;
    guid: string;
    updated: number;
    title: string;
    description: string;
    added: number;
    locked: boolean;
    availableDate: number;
    expirationDate: number;
    active: boolean;
}

class PromotionService {
    private endpoint = "/promotions";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getActivePromotions(): Promise<any> {
        try {
            const res = await this.service.get(this.endpoint);
            return res.data;
        } catch (error: unknown) {
            Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async applyPromotionToContract(contractId: string, promotionId: string): Promise<any> {
        try {
            const res = await this.service.post(`${this.endpoint}/apply`, { contractId, promotionId });
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new PromotionService();
