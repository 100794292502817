import React from "react";
import {ExternalLink} from "react-feather";
import {Container, Spinner, Table, UncontrolledAlert} from "reactstrap";
import {PlanEntry, PlansConfig, ProductTag} from "../../services/PureFlixAdmin/PlansService";

interface Props {
    selectedPlansConfigForPreview: PlansConfig;
    isPreviewLoading: boolean;
    previewResponse: PlanEntry[];
    expectedPlansPreviewCount: number;
}

type PreviewPlan = {
    id: string | undefined;
    title: string;
    description: string;
    scopeIds: (string | undefined)[];
    freeTrial: string;
    freeTrialPeriod: string;
    country: string;
    renewal: string;
    subscriptionUnits: string;
    otherProductTags: ProductTag[];
};

class PlansPreviewPanel extends React.Component<Props> {
    private openHubspotUrl(): void {
        const {pageCode} = this.props.selectedPlansConfigForPreview;
        const suffix = pageCode === "default" ? "" : `-${pageCode}`;
        window.open(`${process.env.REACT_APP_SIGNUP_FUNNEL_URL}/signup/plans${suffix}`);
    }

    private getPreviewPlan = (plan: PlanEntry): PreviewPlan => {
        const {id, description, title, productTags, scopeIds} = plan;
        // The tags that don't have a specific column
        const tagsToFilter = ["FreeTrial", "FreeTrialPeriod", "Renewal", "SubscriptionUnits", "Country"];
        const getProductTagValue = (scheme: string) => {
            const tag = productTags.find((tag) => tag.scheme === scheme);
            return tag?.title || "None";
        };

        return {
            id: id.split("/")?.pop(),
            title,
            description,
            scopeIds: scopeIds.map((id) => id.split("/")?.pop()),
            freeTrial: getProductTagValue("FreeTrial"),
            freeTrialPeriod: getProductTagValue("FreeTrialPeriod"),
            country: getProductTagValue("Country"),
            renewal: getProductTagValue("Renewal"),
            subscriptionUnits: getProductTagValue("SubscriptionUnits"),
            otherProductTags: productTags.filter((tag) => !tagsToFilter.includes(tag.scheme)),
        };
    };

    private getAllPreviewPlans = (plans: PlanEntry[]): PreviewPlan[] => {
        return plans.map((plan) => this.getPreviewPlan(plan));
    };

    private PlansDescriptionTable = ({plans}: {plans?: PlanEntry[]}) => {
        if (!plans?.length) {
            return null;
        }
        const previewPlans = this.getAllPreviewPlans(plans);
        return (
            <Table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Description</th>
                        <th>Scope Ids</th>
                        <th>Free Trial</th>
                        <th>Free Trial Period</th>
                        <th>Country</th>
                        <th>Renewal</th>
                        <th>Subscription Units</th>
                        <th>Other Product Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {previewPlans.map((plan, k) => (
                        <tr key={k}>
                            <td scope="row">{plan.id}</td>
                            <td>{plan.title || plan.description}</td>
                            <td>
                                {plan.scopeIds.map((scopeId, j) => (
                                    <span key={j}>
                                        {scopeId}
                                        <br />
                                    </span>
                                ))}
                            </td>
                            <td>{plan.freeTrial}</td>
                            <td>{plan.freeTrialPeriod}</td>
                            <td>{plan.country}</td>
                            <td>{plan.renewal}</td>
                            <td>{plan.subscriptionUnits}</td>
                            <td>
                                <ul>
                                    {plan.otherProductTags.map((tag, j) => (
                                        <li key={j}>
                                            {tag.scheme}: {tag.title}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    public render(): JSX.Element {
        const {previewResponse, expectedPlansPreviewCount} = this.props;
        return (
            <Container fluid="true" style={{height: 500, overflowY: "scroll"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex"}}>
                        <p className="preview-title">
                            Previewing <strong>{this.props.selectedPlansConfigForPreview.pageCode}</strong>, filter{" "}
                            <strong>{this.props.selectedPlansConfigForPreview.buttons[0].buttonCode}</strong>
                        </p>
                        <ExternalLink className="preview-hubspot-page" onClick={(): void => this.openHubspotUrl()} />
                    </div>
                    {expectedPlansPreviewCount !== previewResponse.length && (
                        <UncontrolledAlert color="warning" className="alert">
                            The amount of plans displayed is different from expected. Please check the filters
                        </UncontrolledAlert>
                    )}
                </div>
                <div className="plans-preview">
                    {this.props.isPreviewLoading ? <Spinner color="primary" id="preview-spinner" /> : <this.PlansDescriptionTable plans={previewResponse} />}
                </div>
            </Container>
        );
    }
}

export default PlansPreviewPanel;
