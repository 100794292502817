import classnames from "classnames";
import { Component } from "react";
import ProgressBar from "react-progress-bar-plus";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Col, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane, UncontrolledAlert } from "reactstrap";
import { AvailableRoutesEnum, TaskRunnerEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { PinoLog } from "../../helpers/PinoLog";
import WebSocketHelper from "../../helpers/WebSocketHelper";
import { withPermission } from "../../hoc/withPermission";
import TaskService from "../../services/PureFlixAdmin/TaskService";
import Title from "../Title";
import LogsTable from "./LogsTable";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

type AlertType = "danger" | "warning" | "success";

interface State {
    alertMessage: string;
    alertType: AlertType;
    loadProgress: number;
    isLoading: boolean;
    activeTab: string;
    showContent: boolean;
    disabled: boolean;
    logsHubspotReport: PinoLog[];
    // logsSitemapReports: PinoLog[];
    logsFacebookReports: PinoLog[];
    logsImpactUpload: PinoLog[];
}

const GuardTaskRunnerButton = withPermission(Button, TaskRunnerEnum.RUN);

class TaskRunner extends Component<PropsType, State> {
    public state: State;
    private _isMounted;
    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            loadProgress: 0,
            isLoading: true,
            activeTab: "hubspot",
            showContent: false,
            disabled: false,
            logsHubspotReport: [],
            // logsSitemapReports: [],
            logsFacebookReports: [],
            logsImpactUpload: [],
        };
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.getReportLogs("hubspot").then(() => {
                const socket = WebSocketHelper.getSocket();
                WebSocketHelper.watchEvent(socket, "hubspot", this.prependToProcessMessages);
                // WebSocketHelper.watchEvent(socket, 'sitemap-generator', this.prependToProcessMessages);
                WebSocketHelper.watchEvent(socket, "facebook-capi", this.prependToProcessMessages);
                WebSocketHelper.watchEvent(socket, "impact-upload", this.prependToProcessMessages);
                WebSocketHelper.watchEvent(socket, "task-runner", this.handleTaskRunnerState);
            });
        }
    }
    public componentWillUnmount() {
        this._isMounted = false;
    }

    private handleTaskRunnerState = (event: any): void => {
        if (this._isMounted) {
            this.setState({
                disabled: event === "running",
            });
        }
    };

    private loaderToZero(): void {
        this.setState({
            loadProgress: 0,
            isLoading: false,
            disabled: false,
            alertMessage: "",
            logsHubspotReport: [],
            // logsSitemapReports: [],
            logsFacebookReports: [],
        });
    }

    private startLoader(): void {
        this.setState({
            loadProgress: 10,
            showContent: false,
            isLoading: true,
            disabled: true,
            alertMessage: "",
        });
    }

    private endLoader(obj: any): void {
        if (this._isMounted) {
            this.setState(
                Object.assign(
                    {
                        loadProgress: 100,
                        showContent: true,
                        isLoading: false,
                    },
                    obj
                )
            );
        }
    }

    private setError = (err: any, alertType: AlertType = "danger"): void => {
        const alertMessage = err.response ? err.response.data.message : err.message;
        this.loaderToZero();
        this.setAlert(alertMessage, alertType);
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private prependToProcessMessages = (log: PinoLog, eventName: string): void => {
        switch (eventName) {
            case "hubspot":
                this.setState({ logsHubspotReport: [log].concat(this.state.logsHubspotReport) });
                break;
            // case 'sitemap-generator':
            //     this.setState({ logsSitemapReports: [log].concat(this.state.logsSitemapReports) });
            //     break;
            case "facebook-capi":
                this.setState({ logsFacebookReports: [log].concat(this.state.logsFacebookReports) });
                break;
            case "impact-upload":
                this.setState({ logsImpactUpload: [log].concat(this.state.logsImpactUpload) });
                break;
            default:
                throw new Error(`invalid event name: ${eventName}`);
        }
    };

    private toggle = async (tab: string): Promise<void> => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                showContent: false,
            });
            await this.getReportLogs(tab);
        }
    };

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            } else {
                this.props.history.push("/", { message: "Unknown error in TaskRunner.tsx:checkLoggedIn" });
            }
            return false;
        }
    };

    private async getReportLogs(reportName: string): Promise<void> {
        try {
            const isLoggedIn = await this.checkLoggedIn();
            if (isLoggedIn && this._isMounted) {
                this.startLoader();
                const { logs, taskStatus } = await TaskService.getLogs(reportName);
                const disabled = taskStatus !== "stopped";
                switch (reportName) {
                    case "hubspot":
                        this.endLoader({ logsHubspotReport: logs, disabled });
                        break;
                    // case 'sitemap-generator':
                    //     this.endLoader({ logsSitemapReports: logs, disabled });
                    //     break;
                    case "facebook-capi":
                        this.endLoader({ logsFacebookReports: logs, disabled });
                        break;
                    case "impact-upload":
                        this.endLoader({ logsImpactUpload: logs, disabled });
                        break;
                    default:
                        throw new Error(`invalid task id: ${reportName}`);
                }
            }
        } catch (err) {
            this.setError(err);
        }
    }

    private async startTask(taskName: string): Promise<void> {
        try {
            const isLoggedIn = await this.checkLoggedIn();
            if (isLoggedIn) {
                this.setState({
                    loadProgress: 10,
                    isLoading: true,
                    disabled: true,
                    alertMessage: "",
                });
                await TaskService.start(taskName);
                this.endLoader({});
            }
        } catch (err) {
            this.setError(err);
        }
    }

    private renderAlert(): JSX.Element {
        const { alertMessage, alertType } = this.state;
        return (
            <UncontrolledAlert className="alert-msg" color={alertType}>
                {alertMessage}
            </UncontrolledAlert>
        );
    }

    public render(): JSX.Element {
        const {
            alertMessage,
            isLoading,
            activeTab,
            showContent,
            loadProgress,
            disabled,
            logsHubspotReport,
            // logsSitemapReports,
            logsFacebookReports,
            logsImpactUpload,
        } = this.state;
        return (
            <div className="tasks-page">
                <ProgressBar percent={loadProgress} spinner={false} autoIncrement={true} />
                <Title content="Task Runner" />

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "hubspot" })}
                            onClick={(): void => {
                                this.toggle("hubspot");
                            }}
                        >
                            Hubspot Report
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'sitemap-generator' })}
                            onClick={(): void => { this.toggle('sitemap-generator'); }}
                        >
                            Sitemap Generator Report
                        </NavLink>
                    </NavItem> */}
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "facebook-capi" })}
                            onClick={(): void => {
                                this.toggle("facebook-capi");
                            }}
                        >
                            Facebook Conversions Report
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "impact-upload" })}
                            onClick={(): void => {
                                this.toggle("impact-upload");
                            }}
                        >
                            Impact Conversions Upload
                        </NavLink>
                    </NavItem>
                </Nav>

                <div id="tasks-content">
                    {isLoading && !showContent && <Spinner color="primary" />}
                    {showContent ? (
                        <div>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="hubspot">
                                    <Row>
                                        <Col sm={3}>
                                            <GuardTaskRunnerButton
                                                color="primary"
                                                className="task-runner-button"
                                                onClick={(): void => {
                                                    this.startTask("hubspot");
                                                }}
                                                disabled={disabled}
                                            >
                                                Run Hubspot Task
                                            </GuardTaskRunnerButton>
                                            {isLoading && <Spinner color="primary" />}
                                        </Col>
                                        <Col sm={9}>{alertMessage && this.renderAlert()}</Col>
                                    </Row>
                                    <LogsTable logs={logsHubspotReport} />
                                </TabPane>
                                {/* <TabPane tabId="sitemap-generator">
                                    <Row>
                                        <Col sm={3}>
                                            <Button color="primary" className="task-runner-button"
                                                onClick={(): void => { this.startTask('sitemap-generator'); }} disabled={disabled}>Run Sitemap Task</Button>
                                            {isLoading && <Spinner color="primary" />}
                                        </Col>
                                        <Col sm={9}>
                                            {alertMessage && this.renderAlert()}
                                        </Col>
                                    </Row>
                                    <LogsTable logs={logsSitemapReports} />
                                </TabPane> */}
                                <TabPane tabId="facebook-capi">
                                    <Row>
                                        <Col sm={3}>
                                            <GuardTaskRunnerButton
                                                color="primary"
                                                className="task-runner-button"
                                                onClick={(): void => {
                                                    this.startTask("facebook-capi");
                                                }}
                                                disabled={disabled}
                                            >
                                                Run Facebook Conversions Task
                                            </GuardTaskRunnerButton>
                                            {isLoading && <Spinner color="primary" />}
                                        </Col>
                                        <Col sm={9}>{alertMessage && this.renderAlert()}</Col>
                                    </Row>
                                    <LogsTable logs={logsFacebookReports} />
                                </TabPane>
                                <TabPane tabId="impact-upload">
                                    <Row>
                                        <Col sm={3}>
                                            <GuardTaskRunnerButton
                                                color="primary"
                                                className="task-runner-button"
                                                onClick={(): void => {
                                                    this.startTask("impact-upload");
                                                }}
                                                disabled={disabled}
                                            >
                                                Run Impact Upload Task
                                            </GuardTaskRunnerButton>
                                            {isLoading && <Spinner color="primary" />}
                                        </Col>
                                        <Col sm={9}>{alertMessage && this.renderAlert()}</Col>
                                    </Row>
                                    <LogsTable logs={logsImpactUpload} />
                                </TabPane>
                            </TabContent>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(withPermission(TaskRunner, AvailableRoutesEnum.TASK_RUNNER));
