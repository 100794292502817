import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Spinner, UncontrolledAlert } from "reactstrap";
import { AvailableRoutesEnum, CompAccountEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { withPermission } from "../../hoc/withPermission";
import CompAccountsService from "../../services/PureFlixAdmin/CompAccountsService";
import { CreateCompAccountPayload } from "../../services/PureFlixAdmin/Requests";
import { CompProduct } from "../../services/PureFlixAdmin/Responses";
import Title from "../Title";
import AddCompSubscriptionForm from "./AddCompSubscriptionForm";

interface PathParamsType {
    history: any;
}

type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        search: string;
    };
};

type AlertType = "danger" | "success";

interface State {
    alertMessage: string;
    alertType: AlertType;
    products: CompProduct[];
    isLoading: boolean;
}

enum STRINGS {
    PAGE_TITLE = "Add a Comp Subscription",
    SHOW_EXISTING_COMP_ACCOUNTS = "List Existing Comp Accounts",
    REGISTRATION_FAILED_USER_EXISTS = "Registration Failed: user already exists!",
    REGISTRATION_USER_CREATED = "Successfully added!",
}
const GuardAddCompSubscriptionForm = withPermission(AddCompSubscriptionForm, CompAccountEnum.CREATE);

class CompAccounts extends Component<PropsType, State> {
    private _isMounted;
    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            products: [],
            isLoading: true,
        };
        this._isMounted = false;
        this.initialize();
    }

    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private async initialize(): Promise<void> {
        this._isMounted = true;
        if (this._isMounted) {
            const isLoggedIn = await this.checkLoggedIn();
            const res = await CompAccountsService.getAvailableProducts();
            if (isLoggedIn && this._isMounted) {
                this.setState({ products: res });
            }
            this.hideSpinner();
        }
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            } else {
                this.props.history.push("/", { message: "Unknown error in CompAccounts.tsx:checkLoggedIn" });
            }
            return false;
        }
    };

    private setError = (err: any): void => {
        const alertMessage = err.response ? err.response.data.message : err.message;
        this.setAlert(alertMessage);
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private showSpinner(): void {
        this.setState({ isLoading: true });
    }

    private hideSpinner(): void {
        if (this._isMounted) {
            this.setState({ isLoading: false });
        }
    }

    private createCompAccount = async (payload: CreateCompAccountPayload): Promise<void> => {
        this.setAlert("");
        this.showSpinner();

        try {
            const userCreated = await CompAccountsService.createAccount(payload);
            if (userCreated) {
                this.handleUserCreated();
            } else {
                this.handleUserExistsError();
            }
        } catch (error: unknown) {
            this.setError(error);
            this.hideSpinner();
            // when the Form component catches the error, it won't be cleared
            if (error instanceof Error) {
                throw Error(error.message);
            } else {
                throw Error("Unknown error in CompAccounts.tsx:createCompAccount");
            }
        }

        this.hideSpinner();
    };

    private handleUserCreated(): void {
        this.setAlert(STRINGS.REGISTRATION_USER_CREATED, "success");
    }

    private handleUserExistsError(): void {
        const userExistsError = new Error(STRINGS.REGISTRATION_FAILED_USER_EXISTS);
        this.setError(userExistsError);
        throw userExistsError;
    }

    private renderAlert(): JSX.Element {
        const { alertMessage, alertType } = this.state;
        return <UncontrolledAlert color={alertType}>{alertMessage}</UncontrolledAlert>;
    }

    public render(): JSX.Element {
        const { products = [], isLoading, alertMessage } = this.state;

        return (
            <div>
                <Title content={STRINGS.PAGE_TITLE} />
                <GuardAddCompSubscriptionForm products={products} submitHandler={this.createCompAccount} errorNotifier={this.setError} disabled={isLoading} />
                {isLoading && <Spinner color="primary" />}
                {alertMessage && this.renderAlert()}
                <br />
            </div>
        );
    }
}

export default withRouter(withPermission(CompAccounts, AvailableRoutesEnum.COMP_ACCOUNTS));
