import React, { useEffect } from "react";
import imageResizerService from "../../services/PureFlixAdmin/ImageResizerService";
import { Thumbnails } from "./Types";

// import { Container } from './styles';

interface Props {
    thumbnails: Thumbnails;
    setLoad: (value: boolean) => void;
    style?: React.CSSProperties,
    imageType: string[];
}

const Thumbnail: React.ElementType = ({
    thumbnails,
    setLoad,
    ...props
}: Props) => {
    const [resizedImageUrl, setResizedImage] = React.useState<
        string | undefined
    >("");
    const [fallbackUrl, setFallbackUrl] = React.useState<string>("");

    useEffect(() => {
        getResizedImage(thumbnails);
    }, [thumbnails]);

    const getResizedImage = async (thumbnails: Thumbnails) => {
        const resizedImage = imageResizerService.getResizedThumbnailUrl({thumbnails, imageType: props.imageType});
        const fallbackUrlImage = imageResizerService.getFallBackThumbnailUrl();

        const [resized, fallback] = await Promise.all([
             await resizedImage,
             await fallbackUrlImage,
        ]);

        setResizedImage(resized);
        setFallbackUrl(fallback);
    };

    return thumbnails ? (
        <div {...props}>
                <div>
                    <img
                        className="thumbnail"
                        src={resizedImageUrl?? fallbackUrl}
                        onLoad={() => setLoad(true)}
                        style={props.style}
                    />
                </div>

        </div>
    ) : null;
};

export default Thumbnail;
